import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-school-grade-list',
  templateUrl: './school-grade-list.component.html',
  styleUrls: ['./school-grade-list.component.css']
})
export class SchoolGradeListComponent implements OnInit, DoCheck {

  public seccionSeleccionada = '';
  public seccionSeleccionada2 = '';
  public nombreSeccionSeleccionada = '';
  public id = '';
  private id2 = '';
  private position = 0;
  public nameOfGrade = '';
  public nameOfGrade2 = '';
  private nameOfGrade3 = '';
  public sections = [];
  public grades = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_grados_de_estudio;
      this.permisoEditar = info.permisos[0].actualizar_grados_de_estudio;
      this.permisoEliminar = info.permisos[0].eliminar_grados_de_estudio;
      if(info.permisos[0].visualizar_grados_de_estudio === true){
        let listado;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.sections = listado;
        });
        let listado2;
        this.api.obtenerListadoGrados(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).grados;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Grados");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Grados");
          }
        }, () => {
          this.grades = listado2;
          this.notify.showInfo("Información actualizada", "Grados");
        });
      } else if(info.permisos[0].agregar_grados_de_estudio !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  ngDoCheck(){
    for(let k = 0; k < this.grades.length; k++){
      for(let i = 0; i < this.sections.length; i++){
        if(this.sections[i].id === this.grades[k].seccion){
          this.grades[k].nombreSeccion = this.sections[i].nombre;
        }
      }
    }
    this.grades.sort(function(a, b){
      if(a.nombreSeccion < b.nombreSeccion) { return -1; }
    });
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }


  listenNameOfGrade(name){
    name.target.value = name.target.value.toUpperCase();
    this.nameOfGrade = name.target.value;
    this.nameOfGrade2 = name.target.value;
  }

  listenIdOfGrade(id){
    this.id2 = id.target.value;
  }

  mostrarGrado(idGrado, nombreGrado, idSeccion, posicionGrado){
    this.id = idGrado;
    this.nameOfGrade2 = nombreGrado;
    this.nameOfGrade3 = nombreGrado;
    this.seccionSeleccionada2 = idSeccion;
    this.position = posicionGrado;
    this.nombreSeccionSeleccionada = this.filtrarSecciones(this.seccionSeleccionada2)[0].nombre;
  }

  agregarGrado(){
    if(this.seccionSeleccionada !== ''){
      if(this.nameOfGrade.trim() !== ''){
        let idRespuesta;
        this.api.agregarUnGrado(this.token, this.seccionSeleccionada, this.nameOfGrade.trim().toUpperCase()).subscribe(respuesta => {
          idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Grados');
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 403) {
            this.notify.showWarning('El nombre ya existe en otro grado. Prueba con otro diferente', 'Grados');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Grados');
          }
        }, () => {
          this.grades.push({
            id: idRespuesta,
            nombre: this.nameOfGrade.trim().toUpperCase(),
            seccion: this.seccionSeleccionada,
            nombreSeccion: this.filtrarSecciones(this.seccionSeleccionada)[0].nombre,
            utilizado: 0
          });
          this.seccionSeleccionada = '';
          this.notify.showInfo('Se agregó el grado correctamente', 'Grados');
        });
      } else {
        this.notify.showWarning('Debes agregar un nombre al grado', 'Grados');
      }
    } else {
      this.notify.showWarning('Debes seleccionar un nivel académico', 'Grados');
    }
  }

  actualizarGrado(){
    if(this.nameOfGrade2.trim() !== this.nameOfGrade3.trim()){
      let estadoRespuesta;
      this.api.actualizarUnGrado(this.token, this.id, this.nameOfGrade2.trim().toUpperCase()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Grados');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El grado que intentas eliminar no existe', 'Grados');
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro grado. Prueba con otro diferente', 'Grados');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Grados');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.notify.showInfo('Se actualizó el grado correctamente', 'Grados');
          this.grades[this.position].nombre = this.nameOfGrade2.trim().toUpperCase();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Secciones');
        }
      });
    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', 'Grados');
    }
  }

  eliminarGrado(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnGrado(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Grados');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El grado que intentas eliminar no existe', 'Secciones');
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro grado. Prueba con otro diferente', 'Grados');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Grados');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.grades.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el grado', 'Grados');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Grados');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'Grados');
    }
  }

  filtrarSecciones(key: string ) {
    return this.sections.filter(object => {
      return object.id === key;
    });
  }

  

}
