import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-of-wikis',
  templateUrl: './list-of-wikis.component.html',
  styleUrls: ['./list-of-wikis.component.css']
})
export class ListOfWikisComponent implements OnInit {

  public id = '';
  private id2 = '';
  private position = 0;
  public titleOfWiki = '';
  public titleOfWiki2 = '';
  private titleOfWiki3 = '';
  public descriptionOfWiki = '';
  public descriptionOfWiki2 = '';
  private descriptionOfWiki3 = '';
  public wikis = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  public seleccionarWiki = false;
  private infoPage;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router, private route: ActivatedRoute) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    if(this.route.snapshot.queryParams.agregando !== undefined){
      this.seleccionarWiki = this.route.snapshot.queryParams.agregando;
    } else if(this.route.snapshot.queryParams.editando !== undefined){
      this.seleccionarWiki = this.route.snapshot.queryParams.editando;
    } else {
      this.seleccionarWiki = false;
    }
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_wikis;
      this.permisoEditar = info.permisos[0].actualizar_wikis;
      this.permisoEliminar = info.permisos[0].eliminar_wikis;
      if(info.permisos[0].visualizar_wikis === true){
        let listado;
        this.api.obtenerListadoWikis(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).wikis;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", 'WIKIS');
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", 'WIKIS');
          }
        }, () => {
          this.wikis = listado;
          this.notify.showInfo("Información actualizada", 'WIKIS');
        });
      } else if(info.permisos[0].agregar_wikis !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenTitleOfWiki(name){
    this.titleOfWiki = name.target.value;
    this.titleOfWiki2 = name.target.value;
  }

  listenDescriptionOfWiki(name){
    this.descriptionOfWiki = name.target.value;
    this.descriptionOfWiki2 = name.target.value;
  }

  listenIdOfPsl(id){
    this.id2 = id.target.value;
  }

  agregarWiki(){
    if(this.titleOfWiki.trim() !== '' && this.descriptionOfWiki.trim() !== ''){
      let idRespuesta;
      this.api.agregarUnaWiki(this.token, this.titleOfWiki.trim(), this.descriptionOfWiki.trim()).subscribe(respuesta => {
        idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'WIKIS');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otra wiki. Prueba con otro diferente', 'WIKIS');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'WIKIS');
        }
      }, () => {
        this.notify.showInfo('Se agregó la wiki correctamente', 'WIKIS');
        this.wikis.push({
          id: idRespuesta,
          titulo: this.titleOfWiki.trim(),
          descripcion: this.descriptionOfWiki.trim(),
          utilizado: 0
        });
      });
    } else {
      if(this.titleOfWiki.trim() === ''){
        this.notify.showWarning('No puedes agregar una wiki sin nombre', 'WIKIS');
      }
      if(this.descriptionOfWiki.trim() === ''){
        this.notify.showWarning('No puedes agregar una wiki sin descripción', 'WIKIS');
      }
    }
  }

  actualizarWiki(){
    if(this.titleOfWiki2.trim() !== this.titleOfWiki3.trim()){
      this.peticionActualizar();
    } else if(this.descriptionOfWiki2.trim() !== this.descriptionOfWiki3.trim()) {
      this.peticionActualizar();
    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', 'WIKIS');
    }
  }

  peticionActualizar(){
    if(this.titleOfWiki2.trim() !== '' && this.descriptionOfWiki2.trim() !== ''){
      let estadoRespuesta;
      this.api.actualizarUnaWiki(this.token, this.id, this.titleOfWiki2.trim(), this.descriptionOfWiki2.trim()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'WIKIS');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otra wiki. Prueba con otro diferente', 'WIKIS');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'WIKIS');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.notify.showInfo('Se actualizó la wiki', 'WIKIS');
          this.wikis[this.position].titulo = this.titleOfWiki2.trim();
          this.wikis[this.position].descripcion = this.descriptionOfWiki2.trim();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'WIKIS');
        }
      });
    } else {
      if(this.titleOfWiki2.trim() === ''){
        this.notify.showWarning('No puedes actualizar una wiki sin nombre', 'WIKIS');
      }
      if(this.descriptionOfWiki2.trim() === ''){
        this.notify.showWarning('No puedes actualizar una wiki sin descripción', 'WIKIS');
      }
    }
  }

  eliminarWiki(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnaWiki(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'WIKIS');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('La wiki que intentas eliminar no existe', 'WIKIS');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'WIKIS');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.wikis.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente la wiki', 'WIKIS');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'WIKIS');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'WIKIS');
    }
  }

  mostrarWiki(idWiki, nombreWiki, descripcionWiki, posicionWiki){
    this.id = idWiki;
    this.titleOfWiki2 = nombreWiki;
    this.titleOfWiki3 = nombreWiki;
    this.descriptionOfWiki2 = descripcionWiki;
    this.descriptionOfWiki3 = descripcionWiki;
    this.position = posicionWiki;
  }

  seleccionaWiki(idWiki, nombreWiki, descripcionWiki, posicionWiki){
    this.id = idWiki;
    this.titleOfWiki2 = nombreWiki;
    this.titleOfWiki3 = nombreWiki;
    this.descriptionOfWiki2 = descripcionWiki;
    this.descriptionOfWiki3 = descripcionWiki;
    this.position = posicionWiki;

    if(this.seleccionarWiki){
      this.infoPage = JSON.parse(localStorage.getItem('detallePagina2'));
      if(this.infoPage.wikis === null){
        this.infoPage.wikis = [];
      }
      this.infoPage.wikis.push({
        id: idWiki,
        titulo: nombreWiki,
        descripcion: descripcionWiki
      });
      this.infoPage.contenido = `${this.infoPage.contenido} #wikiid='W${idWiki}'#`;
      if(this.route.snapshot.queryParams.agregando === 'true'){
        this.router.navigate(['', 'books', 'pages', 'add']);
        localStorage.setItem('detallePagina3', JSON.stringify(this.infoPage));
        localStorage.removeItem('detallePagina2');
      } else if(this.route.snapshot.queryParams.editando === 'true'){
        this.router.navigate(['', 'books', 'pages', 'edit']);
        localStorage.setItem('detallePagina', JSON.stringify(this.infoPage));
        localStorage.removeItem('detallePagina2');
      }
    }
  }

}
