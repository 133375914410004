// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url_principal: 'https://api.ebe.mx/v1.0.0',
  url_principal: 'http://localhost:8443/v1.0.0',
  public: 'V9gAYQwse18WvoPjBbr5ebvCg1EzWOaOQC5c5lR2lSnJYyTky664kA14e9HoMw4ciuvAiBxN30wRavmT8CIq30V0LnQ9cgANIqBgtNIQqoiniFCk1zdQVK8GIoqtiptZ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
