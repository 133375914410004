import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-topics-in-a-subject',
  templateUrl: './list-of-topics-in-a-subject.component.html',
  styleUrls: ['./list-of-topics-in-a-subject.component.css']
})
export class ListOfTopicsInASubjectComponent implements OnInit {

  public id = '';
  private id2 = '';
  private position = 0;
  public nameOfTopic = '';
  public nameOfTopic2 = '';
  private nameOfTopic3 = '';
  public nameOfExpectedLearning = '';
  public nameOfExpectedLearning2 = '';
  private nameOfExpectedLearning3 = '';
  private topics = [];
  public topics2 = [];
  private subjects = [];
  public subjects2 = [];
  public filteredSubjetcs = [];
  public sections = [];
  public seccionSeleccionada3 = '';
  public seccionSeleccionada7 = '';
  public materiaSeleccionada3 = '';
  public materiaSeleccionada6 = '';
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_temas;
      this.permisoEditar = info.permisos[0].actualizar_temas;
      this.permisoEliminar = info.permisos[0].eliminar_temas;
      if(info.permisos[0].visualizar_temas === true){
        let listado3;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado3 = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          // console.log("NIVELES: ", listado3);
          this.sections = listado3;
        });
        let listado2;
        this.api.obtenerListadoMaterias(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).materias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          // console.log("MATERIAS: ", listado2);
          this.subjects = listado2;
        });
        let listado;
        this.api.obtenerListadoTemas(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).temas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "TEMAS");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "TEMAS");
          }
        }, () => {
          this.topics = listado;
          this.topics2 = listado;
          this.notify.showInfo("Información actualizada", "TEMAS");
        });
      } else if(info.permisos[0].agregar_wikis !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  ngDoCheck(){
    for(let k = 0; k < this.topics.length; k++){
      for(let i = 0; i < this.subjects.length; i++){
        if(this.subjects[i].id === this.topics[k].materia){
          this.topics[k].tituloMateria = this.subjects[i].titulo;
        }
      }
    }
    this.topics.sort(function(a, b){
      if(a.tituloMateria < b.tituloMateria) { return -1; }
    });
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenNameOfTopic(name){
    this.nameOfTopic = name.target.value;
    this.nameOfTopic2 = name.target.value;
  }

  listenExpectedLearningOfPSL(name){
    this.nameOfExpectedLearning = name.target.value;
    this.nameOfExpectedLearning2 = name.target.value;
  }

  listenIdOfTopic(id){
    this.id2 = id.target.value;
  }

  seleccionarNivel(){
    this.materiaSeleccionada6 = '';
    if(this.seccionSeleccionada7 !== ''){
      // this.listOfPsl2 = this.filtrarPslPorMateria(this.materiaSeleccionada5);
      this.subjects2 = this.filtrarMateriasPorNivel(this.seccionSeleccionada7);
      this.subjects2.sort(function(a, b){
        if(a.titulo < b.titulo) { return -1; }
      });
      this.topics2 = [];
      this.notify.showWarning('Selecciona una materia para visualizar sus temas', 'Temas');
    } else {
      this.subjects2 = [];
      this.topics2 = this.topics;
    }
  }

  seleccionarMateria(){
    if(this.materiaSeleccionada6 !== ''){
      this.topics2 = this.filtrarTopicsPorMateria(this.materiaSeleccionada6);
    } else {
      this.topics2 = [];
      this.notify.showWarning('Selecciona una materia para visualizar sus temas', 'Temas');
    }
  }

  filtrarMateriasPorNivel(key: string ) {
    return this.subjects.filter(object => {
      return (object.seccion === key);
    });
  }

  filtrarTopicsPorMateria(key: string ) {
    return this.topics.filter(object => {
      return (object.materia === key);
    });
  }

  filtrarMaterias(key: string ) {
    return this.subjects.filter(object => {
      return (object.seccion === key && object.tiene_ambito === false);
    });
  }

  filtrarMateriasPorId(key: string ) {
    return this.subjects.filter(object => {
      return object.id === key;
    });
  }

  seleccionarNivelAcademico(){
    if(this.seccionSeleccionada3 !== ''){
      this.filteredSubjetcs = this.filtrarMaterias(this.seccionSeleccionada3);
      this.filteredSubjetcs.sort(function(a, b){
        if(a.titulo < b.titulo) { return -1; }
      });
    } else {
      this.filteredSubjetcs = [];
    }
  }

  agregarTopic(){
    if(this.nameOfTopic.trim() !== '' && this.nameOfExpectedLearning.trim() !== ''){
      if(this.materiaSeleccionada3 !== ''){
        let idRespuesta;
        this.api.agregarUnTema(this.token, this.nameOfTopic.trim(), this.nameOfExpectedLearning.trim(), this.materiaSeleccionada3).subscribe(respuesta => {
          idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', "TEMAS");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 403) {
            this.notify.showWarning('El aprendizaje ya existe en otro tema. Prueba con otro diferente', "TEMAS");
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', "TEMAS");
          }
        }, () => {
          this.notify.showInfo('Se agregó el tema correctamente', "TEMAS");
          this.topics.push({
            id: idRespuesta,
            materia: this.materiaSeleccionada3,
            titulo: this.nameOfTopic.trim(),
            tituloMateria: this.filtrarMateriasPorId(this.materiaSeleccionada3)[0].titulo,
            aprendizaje: this.nameOfExpectedLearning.trim(),
            utilizado: 0
          });
        });
      } else {
        this.notify.showWarning('Debes seleccionar una materia para el tema', "TEMAS");
      }
    } else {
      if(this.nameOfTopic.trim() === ''){
        this.notify.showWarning('No puedes agregar un tema sin nombre', "TEMAS");
      }
      if(this.nameOfExpectedLearning.trim() === ''){
        this.notify.showWarning('No puedes agregar un tema sin aprendizaje esperado', "TEMAS");
      }
    }
  }

  actualizarTopic(){
    if(this.nameOfExpectedLearning2.trim() !== this.nameOfExpectedLearning3.trim()) {
      this.peticionActualizar();
    } else {
      this.notify.showWarning('El aprendizaje no puede ser el mismo', "TEMAS");
    }
  }

  peticionActualizar(){
    if(this.nameOfTopic2.trim() !== '' && this.nameOfExpectedLearning2.trim() !== ''){
      let estadoRespuesta;
      this.api.actualizarUnTema(this.token, this.id, this.nameOfTopic2.trim(), this.nameOfExpectedLearning2.trim()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', "TEMAS");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El aprendizaje ya existe en otro tema. Prueba con otro diferente', "TEMAS");
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', "TEMAS");
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.notify.showInfo('Se actualizó el tema', "TEMAS");
          this.topics[this.position].titulo = this.nameOfTopic2.trim();
          this.topics[this.position].aprendizaje = this.nameOfExpectedLearning2.trim();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', "TEMAS");
        }
      });
    } else {
      if(this.nameOfTopic2.trim() === ''){
        this.notify.showWarning('No puedes actualizar un tema sin nombre', "TEMAS");
      }
      if(this.nameOfExpectedLearning2.trim() === ''){
        this.notify.showWarning('No puedes actualizar un tema sin aprendizaje esperado', "TEMAS");
      }
    }
  }

  eliminarTopic(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnTema(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', "TEMAS");
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El tema que intentas eliminar no existe', "TEMAS");
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', "Ejes");
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.topics.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el tema', "TEMAS");
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', "TEMAS");
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', "TEMAS");
    }
  }

  mostrarTopic(idTopic, nombreTopic, aprendizajeTopic, posicionTopic){
    this.id = idTopic;
    this.nameOfTopic2 = nombreTopic;
    this.nameOfTopic3 = nombreTopic;
    this.nameOfExpectedLearning2 = aprendizajeTopic;
    this.nameOfExpectedLearning3 = aprendizajeTopic;
    this.position = posicionTopic;
  }

}
