import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor() { }

  guardarToken(token) {
    localStorage.setItem('dG9rZW5FQkU=', token);
  }

  obtenertoken() {
    return localStorage.getItem('dG9rZW5FQkU=');
  }

  eliminarToken() {
    localStorage.removeItem('dG9rZW5FQkU=');
  }

}
