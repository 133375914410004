/* eslint no-use-before-define: 0 */
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from "@angular/forms";
import { environment } from '../../../../../environments/environment.prod';

@Component({
  selector: 'app-multimedia-images',
  templateUrl: './multimedia-images.component.html',
  styleUrls: ['./multimedia-images.component.css']
})
export class MultimediaImagesComponent implements OnInit {

  //INICIA PAGINADO
  config: any;
  public labels: any = {
    previousLabel: '< Anterior',
    nextLabel: 'Siguiente >',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = true;
  //FINALIZA PAGINADO
  public id = '';
  public id2 = '';
  public id3 = '';
  private position = 0;
  public descriptionOfImage = '';
  public descriptionOfImage2 = '';
  private descriptionOfImage3 = '';
  public images = [];
  private images2 = [];
  private images3 = [];
  public permisoVisualizar = false;
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  public urlImage = '';
  opciones: NgbModalOptions;
  resultado: string;
  listadoDeArchivos = [];
  listadoDeArchivos2 = [];
  listadoDeImagenes = [];
  listadoDeObjetos = [];
  formulario: FormGroup;
  barraDeProgreso: number = 0;
  public inhabilitarElBoton = false;
  public enProceso = false;
  private token = '';
  private urlBucket = 'https://www.jenios.mx';
  public seleccionarImage = false;
  private agregarEnGaleria = false;
  private agregandoPortada = false;
  private agregandoPagina = false;
  private infoPage;
  public showUploadImages = false;
  public tipoImagenFiltro = 'T';

  public configuracion: DropzoneConfigInterface = {
    clickable: true,
    url: 'https://jenios.mx',
    paramName: 'file',
    maxFiles: 10,
    maxFilesize: 5,
    dictFileTooBig: 'La imágen es más grande de 5MB',
    uploadMultiple: true,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    acceptedFiles: 'image/png,image/jpg,image/jpeg,image/gif'
  };

  constructor(private modal: NgbModal,
    private notify: ToastNotificationService,
    private api: RestfulApiService,
    private auth: AuthServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public formBuilder: FormBuilder,) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    this.formulario = this.formBuilder.group({
      file: [null]
    });
    this.config = {
      itemsPerPage: 12,
      currentPage: 1,
      totalItems: this.images.length
    };
  }

  onPageChange(event) {
    //console.log(event);
    this.config.currentPage = event;
  }


  ngOnInit() {
    if (this.route.snapshot.queryParams.agregando !== undefined) {
      this.seleccionarImage = true;
      this.agregandoPagina = true;
    } else if (this.route.snapshot.queryParams.agregandoguia !== undefined) {
      this.seleccionarImage = true;
      this.agregandoPagina = true;
    } else if (this.route.snapshot.queryParams.editando !== undefined) {
      this.seleccionarImage = true;
      this.agregandoPagina = true;
    } else if (this.route.snapshot.queryParams.editandoguia !== undefined) {
      this.seleccionarImage = true;
      this.agregandoPagina = true;
    } else if (this.route.snapshot.queryParams.galeria !== undefined) {
      this.seleccionarImage = true;
      this.agregarEnGaleria = true;
    } else if (this.route.snapshot.queryParams.agregandoMateria !== undefined) {
      this.seleccionarImage = true;
      if (this.route.snapshot.queryParams.agregandoMateria === 'true') {
        this.agregandoPortada = true;
      } else {
        this.agregandoPortada = false;
      }
    } else {
      this.seleccionarImage = false;
    }
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if (!tokenEspirado && this.token !== null) {
      this.permisoVisualizar = info.permisos[0].visualizar_videos;
      this.permisoAgregar = info.permisos[0].agregar_imagenes;
      this.permisoEditar = info.permisos[0].actualizar_imagenes;
      this.permisoEliminar = info.permisos[0].eliminar_imagenes;
      if (info.permisos[0].visualizar_imagenes === true) {
        // this.api.obtenerEnlaceJeniosBucket().subscribe(respuesta => {
        //   this.urlBucket = JSON.parse(JSON.stringify(respuesta)).url;
        // }, (err: HttpErrorResponse) => {
        //   if (err.error instanceof Error) {
        //     //console.log('Client-side error occured.');
        //   } else {
        //     //console.log('Server-side error occured.');
        //   }
        //   if (err.status === 400) {
        //     this.notify.showError("Error 400", 'IMÁGENES');
        //   }
        //   if (err.status === 401 || err.status === 404) {
        //     this.auth.eliminarToken();
        //     this.router.navigate(['', 'auth']);
        //   }
        //   if (err.status === 500) {
        //     this.notify.showError("Error 500", 'IMÁGENES');
        //   }
        // });
        let listado;
        this.api.obtenerListadoImagenes(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).imagenes;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", 'IMÁGENES');
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", 'IMÁGENES');
          }
        }, () => {
          // console.log("listado: ", listado);
          this.images = listado;
          this.images2 = listado;
          this.images.sort(function (a, b) {
            if (a.date > b.date) { return -1; }
          });
          this.images2.sort(function (a, b) {
            if (a.date > b.date) { return -1; }
          });
          // console.log("listado2 : ", this.images);
          this.seleccionarTipoImagenes();
          this.notify.showInfo("Información actualizada", 'IMÁGENES');
        });
      } else if (info.permisos[0].agregar_imagenes !== true) {
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  compareFunction(a, b) {
    return b.date < a.date
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return `${reason}`;
    }
  }

  subirImagenes(e) {
    this.listadoDeArchivos = Array.from(e);
    this.listadoDeArchivos.forEach((item, i) => {
      const file = (e as HTMLInputElement);
      if (file[i].type === 'image/png' || file[i].type === 'image/jpg' || file[i].type === 'image/jpeg' || file[i].type === 'image/gif') {
        const url = URL.createObjectURL(file[i]);
        this.listadoDeImagenes.push(url);
        this.listadoDeArchivos2.push({ item, url: url });
      } else {
        this.notify.showError('error', 'Asegúrate de subir archivos de tipo imagen');
      }
    });
    this.listadoDeArchivos2.forEach((item) => {
      this.listadoDeObjetos.push(item.item);
    });
    // Set files formulario control
    this.formulario.patchValue({
      file: this.listadoDeObjetos
    });

    this.formulario.get('file').updateValueAndValidity();
  }

  // Clean Url
  limpiarUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  quitarImagen(position) {
    this.listadoDeImagenes.splice(position, 1);
    this.listadoDeObjetos.splice(position, 1);
    this.listadoDeArchivos2.splice(position, 1);

    this.formulario.value.file = null;

    // Set files form control
    this.formulario.patchValue({
      file: this.listadoDeObjetos
    });

    this.formulario.get('file').updateValueAndValidity();
  }

  subirMultimedia() {

    // SUBIR IMÁGENES A JENIOS BUCKET
    var listadoImagenes = [];
    this.urlBucket = environment.url_bucket;

    // REMPLAZAR URL POR http://localhost:8443
    this.api.subirMultimedia(this.formulario.value.file, this.urlBucket, 'img')
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            this.notify.showWarning('Por favor espere...', 'Subiendo imágenes');
            this.inhabilitarElBoton = true;
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.barraDeProgreso = Math.round(event.loaded / event.total * 100);
            // console.log(`Uploaded! ${this.barraDeProgreso}%`);
            if (this.barraDeProgreso === 100) {
              this.notify.showInfo('Por favor espere...', 'Procesando imágenes');
              this.enProceso = true;
            }
            break;
          case HttpEventType.Response:
            console.log('File uploaded successfully!', event.body);
            if (event.body.code === 200) {
              const { file, uid } = event.body;

              this.inserImageOnDB({ file, uid });
              //listadoImagenes = event.body.images;
              this.notify.showSuccess('Se completó la subida de imágenes', 'Felicidades');

            } else {
              this.barraDeProgreso = 0;
              this.notify.showError('Póngase en contacto con el equipo de soporte', 'Ocurrió un problema');
              this.inhabilitarElBoton = false;
            }
        }
      });
  }

  inserImageOnDB(fileUploaded: any) {

    const { file, uid } = fileUploaded;
    const bucket = this.urlBucket;
    let imageInsertInfo = {
      "en_uso": 0,
      "descripcion": "",
      "uid": `${uid}`,
      "src": `${bucket}/uploads/${file}`
    }

    this.api.insertMultimediaonDB(bucket, imageInsertInfo, "imagenes")
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            if (event.body.status === 200) {
              console.log('File CREATE successfully!', event.body);
              this.notify.showSuccess('Se ha insertado la imagen en DB', 'Felicidades');

              this.images.unshift(event.body.details);
              this.images2.unshift(event.body.details);

              this.seleccionarTipoImagenes();
              this.inhabilitarElBoton = false;
              this.enProceso = false;
              this.barraDeProgreso = 0;
              this.listadoDeImagenes = [];
              this.listadoDeArchivos = [];
              this.listadoDeArchivos2 = [];
              this.listadoDeObjetos = [];

            } else {
              this.notify.showError('Póngase en contacto con el equipo de soporte', 'Ocurrió un problema');
            }
        }
      });
  }
  mostrarInfo(idObject, descriptionObject, posicionObject) {
    this.id = idObject;
    this.descriptionOfImage2 = descriptionObject;
    this.descriptionOfImage3 = descriptionObject;
    if (this.config.currentPage > 1) {
      posicionObject = (this.config.currentPage * this.config.itemsPerPage) - (this.config.itemsPerPage - posicionObject);
    }
    this.position = posicionObject;
  }

  seleccionaImagen(idObject, descriptionObject, posicionObject) {
    this.id = idObject;
    this.descriptionOfImage2 = descriptionObject;
    this.descriptionOfImage3 = descriptionObject;
    if (this.config.currentPage > 1) {
      posicionObject = (this.config.currentPage * this.config.itemsPerPage) - (this.config.itemsPerPage - posicionObject);
    }
    this.position = posicionObject;


    if (this.seleccionarImage) {
      if (this.agregarEnGaleria) {
        let objetoGaleria = JSON.parse(localStorage.getItem('detalleGalerias'));
        if (objetoGaleria.imagenes === null) {
          objetoGaleria.imagenes = [];
        }
        let contador = 0;
        let imagenYaSeEncuentraEnGaleria = false;
        for (let i = 0; i < objetoGaleria.imagenes.length; i++) {
          contador = contador + 1;
          if (objetoGaleria.imagenes[i].id === idObject) {
            imagenYaSeEncuentraEnGaleria = true;
          }
        }
        if (contador === objetoGaleria.imagenes.length) {
          if (!imagenYaSeEncuentraEnGaleria) {
            objetoGaleria.imagenes.push({
              id: idObject,
              src: this.images[posicionObject].src
            });
          }
          if (this.route.snapshot.queryParams.galeria === 'true') {
            localStorage.setItem('detalleGalerias2', JSON.stringify(objetoGaleria));
            localStorage.removeItem('detalleGalerias');
            this.router.navigate(['', 'multimedia', 'galleries']);
          }
        }
      } else if (this.agregandoPortada === true && this.agregandoPagina === false) {
        const infoMateria = JSON.parse(localStorage.getItem('infoMateria'));
        infoMateria.portada = this.images[posicionObject].src;
        localStorage.setItem('infoMateria2', JSON.stringify(infoMateria));
        localStorage.removeItem('infoMateria');
        localStorage.removeItem('infoMateria3');
        this.router.navigate(['', 'general', 'subjects']);
      } else if (this.agregandoPortada === false && this.agregandoPagina === false) {
        const infoMateria = JSON.parse(localStorage.getItem('infoMateria'));
        infoMateria.portada = this.images[posicionObject].src;
        localStorage.setItem('infoMateria3', JSON.stringify(infoMateria));
        localStorage.removeItem('infoMateria');
        localStorage.removeItem('infoMateria2');
        this.router.navigate(['', 'general', 'subjects']);
      } else {
        this.infoPage = JSON.parse(localStorage.getItem('detallePagina2'));
        if (this.infoPage.imagenes === null) {
          this.infoPage.imagenes = [];
        }
        if (this.infoPage.imagenes2 === null) {
          this.infoPage.imagenes2 = [];
        }
        if (this.route.snapshot.queryParams.agregando === 'true') {
          this.infoPage.imagenes.push({
            id: idObject,
            src: this.images[posicionObject].src,
            descripcion: descriptionObject
          });
          this.infoPage.contenido = `${this.infoPage.contenido} #imagenid='I${idObject}'#`;
          this.router.navigate(['', 'books', 'pages', 'add']);
          localStorage.setItem('detallePagina3', JSON.stringify(this.infoPage));
          localStorage.removeItem('detallePagina2');
        } else if (this.route.snapshot.queryParams.agregandoguia === 'true') {
          this.infoPage.imagenes2.push({
            id: idObject,
            src: this.images[posicionObject].src,
            descripcion: descriptionObject
          });
          this.infoPage.contenido2 = `${this.infoPage.contenido2} #imagenid='I${idObject}'#`;
          this.router.navigate(['', 'books', 'pages', 'add']);
          localStorage.setItem('detallePagina3', JSON.stringify(this.infoPage));
          localStorage.removeItem('detallePagina2');
        } else if (this.route.snapshot.queryParams.editando === 'true') {
          this.infoPage.imagenes.push({
            id: idObject,
            src: this.images[posicionObject].src,
            descripcion: descriptionObject
          });
          this.infoPage.contenido = `${this.infoPage.contenido} #imagenid='I${idObject}'#`;
          this.router.navigate(['', 'books', 'pages', 'edit']);
          localStorage.setItem('detallePagina', JSON.stringify(this.infoPage));
          localStorage.removeItem('detallePagina2');
        } else if (this.route.snapshot.queryParams.editandoguia === 'true') {
          this.infoPage.imagenes2.push({
            id: idObject,
            src: this.images[posicionObject].src,
            descripcion: descriptionObject
          });
          this.infoPage.contenido2 = `${this.infoPage.contenido2} #imagenid='I${idObject}'#`;
          this.router.navigate(['', 'books', 'pages', 'edit']);
          localStorage.setItem('detallePagina', JSON.stringify(this.infoPage));
          localStorage.removeItem('detallePagina2');
        }
      }
    }
  }

  listenDescription(name) {
    this.descriptionOfImage = name.target.value;
    this.descriptionOfImage2 = name.target.value;
  }

  listenId(id) {
    this.id2 = id.target.value;
  }

  actualizarImagen() {
    if (this.descriptionOfImage2.trim() !== this.descriptionOfImage3.trim()) {
      this.peticionActualizar();
    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', 'IMÁGENES');
    }
  }

  peticionActualizar() {
    if (this.descriptionOfImage2.trim() !== '') {
      let estadoRespuesta;
      this.api.actualizarUnaImagen(this.token, this.id, this.descriptionOfImage2.trim()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'IMÁGENES');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otra imágen. Prueba con otro diferente', 'IMÁGENES');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'IMÁGENES');
        }
      }, () => {
        if (estadoRespuesta === 200) {
          this.notify.showInfo('Se actualizó la imágen', 'IMÁGENES');
          this.images[this.position].descripcion = this.descriptionOfImage2.trim();
          this.images2[this.position].descripcion = this.descriptionOfImage2.trim();
          this.seleccionarTipoImagenes();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'IMÁGENES');
        }
      });
    } else {
      if (this.descriptionOfImage2.trim() === '') {
        this.notify.showWarning('No puedes actualizar una imágen sin descripción', 'IMÁGENES');
      }
    }
  }

  eliminarImagen() {
    if (this.id2.trim() === this.id.trim()) {
      let estadoRespuesta;
      this.api.eliminarUnaImagen(this.id, this.images[this.position].farm, this.images[this.position].server).subscribe(respuesta => {
        // console.log("RESPUESTA ELIMINAR IMAGEN: ", respuesta);
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).status;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'IMÁGENES');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('La imágen que intentas eliminar no existe', 'IMÁGENES');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'IMÁGENES');
        }
      }, () => {
        if (estadoRespuesta === 200) {
          this.images.splice(this.position, 1);
          this.images2.splice(this.position, 1);
          this.seleccionarTipoImagenes();
          this.notify.showInfo('Se eliminó correctamente la imágen', 'IMÁGENES');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'IMÁGENES');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'IMÁGENES');
    }
  }

  showImageBig(url) {
    this.urlImage = url;
  }

  showUploadImagesOption() {
    this.showUploadImages = !this.showUploadImages;
  }

  seleccionarTipoImagenes() {
    //this.tipoImagenFiltro
    //this.id3
    // console.log("TIPO: ", this.tipoImagenFiltro);
    if (this.tipoImagenFiltro === 'T') {
      this.images = [...this.images2];
      this.images3 = [...this.images2];
      // this.images.sort((a, b) => {return a-b});
      // this.images3.sort((a, b) => {return a-b});
      this.images.sort(function (a, b) {
        if (a.date > b.date) { return -1; }
      });
      this.images3.sort(function (a, b) {
        if (a.date > b.date) { return -1; }
      });
    } else if (this.tipoImagenFiltro === 'E') {
      this.images = this.filtrarImagenesPorTipo('E');
      this.images3 = this.filtrarImagenesPorTipo('E');;
      // this.images.sort((a, b) => {return a-b});
      // this.images3.sort((a, b) => {return a-b});
      this.images.sort(function (a, b) {
        if (a.date > b.date) { return -1; }
      });
      this.images3.sort(function (a, b) {
        if (a.date > b.date) { return -1; }
      });
    } else if (this.tipoImagenFiltro === 'S') {
      this.images = this.filtrarImagenesPorTipo('S');
      this.images3 = this.filtrarImagenesPorTipo('S');;
      // this.images.sort((a, b) => {return a-b});
      // this.images3.sort((a, b) => {return a-b});
      this.images.sort(function (a, b) {
        if (a.date > b.date) { return -1; }
      });
      this.images3.sort(function (a, b) {
        if (a.date > b.date) { return -1; }
      });
    }
    this.id3 = '';
  }

  // listenIdOfImage(id){
  //   this.id3 = id.target.value.trim();
  // }

  listenIdOfImage(event) {
    const val = event.target.value;
    this.id3 = val.trim();
    //#imagenid='IISmt7X7wli'#
    const newID = val.trim().substring(12, val.trim().length - 2);

    if (newID !== '') {
      console.log("this.images3", this.images3)
      const temp = this.images3.filter(function (d) {
        return d.id.indexOf(newID) !== -1 || !newID;
      });
      // update the rows
      this.images = temp;
      // Whenever the filter changes, always go back to the first page
    } else {
      this.images = this.images3;
    }
  }

  filtrarImagenesPorTipo(key) {
    return this.images2.filter(object => {
      if (key === 'E') {
        return object.en_uso > 0;
      }
      if (key === 'S') {
        return object.en_uso === 0;
      }
    });
  }

  filtrarImagenesPorId(key) {
    return this.images3.filter(object => {
      return object.id === key;
    });
  }
}
