import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './components/auth/signin/signin.component';
import { Error404Component } from './components/errors/error404/error404.component';
import { ListOfAcademicLevelsComponent } from './components/general/academic-levels/list-of-academic-levels/list-of-academic-levels.component';
import { ListOfAmbitsComponent } from './components/general/ambits/list-of-ambits/list-of-ambits.component';
import { SchoolGradeListComponent } from './components/general/school-grades/school-grade-list/school-grade-list.component';
import { ListOfSchoolSubjectsComponent } from './components/general/school-subjects/list-of-school-subjects/list-of-school-subjects.component';
import { ListOfBlocksOfASubjectComponent } from './components/general/blocks-of-a-subject/list-of-blocks-of-a-subject/list-of-blocks-of-a-subject.component';
import { ListOfSequencesOfASubjectComponent } from './components/general/sequences-of-a-subject/list-of-sequences-of-a-subject/list-of-sequences-of-a-subject.component';
import { ListOfCurricularAxesComponent } from './components/general/curricular-axes/list-of-curricular-axes/list-of-curricular-axes.component';
import { ListOfPagesOfATopicComponent } from './components/general/pages-of-a-topic/list-of-pages-of-a-topic/list-of-pages-of-a-topic.component';
import { AddAPageToATopicComponent } from './components/general/pages-of-a-topic/add-a-page-to-a-topic/add-a-page-to-a-topic.component';
import { EditATopicPageComponent } from './components/general/pages-of-a-topic/edit-a-topic-page/edit-a-topic-page.component';
import { ListOfSocialPracticesOfLanguageComponent } from './components/general/social-practices-of-language/list-of-social-practices-of-language/list-of-social-practices-of-language.component';
import { ListOfTopicsInASubjectComponent } from './components/general/topics-in-a-subject/list-of-topics-in-a-subject/list-of-topics-in-a-subject.component';
import { ListOfWikisComponent } from './components/general/wikis/list-of-wikis/list-of-wikis.component';
import { ListOfQuizzesComponent } from './components/general/quizzes/list-of-quizzes/list-of-quizzes.component';
import { MultimediaImagesComponent } from './components/general/multimedia/multimedia-images/multimedia-images.component';
import { MultimediaGalleriesComponent } from './components/general/multimedia/multimedia-galleries/multimedia-galleries.component';
import { MultimediaVideosComponent } from './components/general/multimedia/multimedia-videos/multimedia-videos.component';
import { ViewPageOfATopicComponent } from './components/general/pages-of-a-topic/view-page-of-a-topic/view-page-of-a-topic.component';
import { GroupListComponent } from './components/admin/group-list/group-list.component';
import { TeacherListComponent } from './components/admin/teacher-list/teacher-list.component';
import { StudentListComponent } from './components/admin/student-list/student-list.component';
import { ListOfSpecialistsComponent } from './components/admin/list-of-specialists/list-of-specialists.component';
import { ListOfSchoolsComponent } from './components/admin/group-list/list-of-schools/list-of-schools.component';
import { ClassListComponent } from './components/admin/class-list/class-list/class-list.component';
import { AddSpecialistComponent } from './components/admin/add-specialist/add-specialist.component';
import { EditSpecialistComponent } from './components/admin/edit-specialist/edit-specialist.component';
import { MultimediaAudiosComponent } from './components/general/multimedia/multimedia-audios/multimedia-audios.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: SigninComponent,
  },
  {
    path: 'admin', children: [
      {path: 'schools', component: ListOfSchoolsComponent},
      {path: 'groups', component: GroupListComponent},
      {path: 'teachers', component: TeacherListComponent},
      {path: 'students', component: StudentListComponent},
      {path: 'classes', component: ClassListComponent},
      {path: 'specialists', children: [
        {path: '', component: ListOfSpecialistsComponent},
        {path: 'add', component: AddSpecialistComponent},
        {path: 'edit', component: EditSpecialistComponent},
        {path: '**', component: Error404Component}
      ]}
    ]
  },
  {
    path: 'general', children: [
      {path: 'levels', component: ListOfAcademicLevelsComponent},
      {path: 'grades', component: SchoolGradeListComponent},
      {path: 'subjects', component: ListOfSchoolSubjectsComponent},
      {path: 'wikis', component: ListOfWikisComponent},
      {path: 'quizzes', component: ListOfQuizzesComponent},
      {path: '**', component: Error404Component}
    ]
  },
  {
    path: 'organizers', children: [
      {path: 'blocks', component: ListOfBlocksOfASubjectComponent},
      {path: 'ambits', component: ListOfAmbitsComponent},
      {path: 'axes', component: ListOfCurricularAxesComponent},
      {path: 'social-practices', component: ListOfSocialPracticesOfLanguageComponent},
      {path: 'topics', component: ListOfTopicsInASubjectComponent},
      {path: '**', component: Error404Component}
    ]
  },
  {
    path: 'books', children: [
      {path: 'sequences', component: ListOfSequencesOfASubjectComponent},
      {path: 'pages', children: [
        {path: '', component: ListOfPagesOfATopicComponent},
        {path: 'add', component: AddAPageToATopicComponent},
        {path: 'edit', component: EditATopicPageComponent},
        {path: 'view', component: ViewPageOfATopicComponent},
        {path: '**', component: Error404Component}
      ]}
    ]
  },
  {
    path: 'multimedia', children: [
      {path: 'images', component: MultimediaImagesComponent},
      {path: 'galleries', component: MultimediaGalleriesComponent},
      {path: 'videos', component: MultimediaVideosComponent},
      {path: 'audios', component: MultimediaAudiosComponent},
      {path: '**', component: Error404Component}
    ]
  },
  {path: '**', component: Error404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
