import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';


@Component({
  selector: 'app-list-of-schools',
  templateUrl: './list-of-schools.component.html',
  styleUrls: ['./list-of-schools.component.css']
})
export class ListOfSchoolsComponent implements OnInit {

  public showInfoEscuela = false;
  public showInfoEscuela2 = false;
  public claveEscuela = 'Cargando...';
  public passEscuela = 'Cargando...';
  public passEscuela2 = 'Cargando...';
  public id = '';
  private id2 = '';
  private position = 0;
  public nameOfSchool = '';
  public nameOfSchool2 = '';
  private nameOfSchool3 = '';
  public countryOfSchool = '';
  public countryOfSchool2 = '';
  private countryOfSchool3 = '';
  public stateOfSchool = '';
  public stateOfSchool2 = '';
  private stateOfSchool3 = '';
  public cityOfSchool = '';
  public cityOfSchool2 = '';
  private cityOfSchool3 = '';
  public sectionsOfSchool = [];
  public schools = [];
  public levels = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoRestablecerPass = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_escuela;
      this.permisoEditar = info.permisos[0].actualizar_escuela;
      this.permisoRestablecerPass = info.permisos[0].restablecer_pass_escuela;
      this.permisoEliminar = info.permisos[0].eliminar_escuela;
      if(info.permisos[0].visualizar_escuelas === true){
        let listado;
        this.api.obtenerListadoEscuelas(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).escuelas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Escuelas");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Escuelas");
          }
        }, () => {
          this.schools = listado;
          this.schools.sort(function(a, b){
            if(a.id < b.id) { return -1; }
          });
          this.notify.showInfo("Información actualizada", "Escuelas");
        });
        let listado2;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.levels = listado2;
          for(let i = 0; i < this.levels.length;i++){
            this.levels[i].selected = false;
          }
        });
      } else if(info.permisos[0].agregar_escuela !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
      // RESET DE LEVELS
      for(let i = 0; i < this.levels.length; i++){
        this.levels[i].selected = false;
      }
      this.showInfoEscuela = false;
      this.showInfoEscuela2 = false;
      this.claveEscuela = 'Cargando...';
      this.passEscuela = 'Cargando...';
      this.passEscuela2 = 'Cargando...';
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      //console.log(`Dismissed: ${reason}`);
      // RESET DE LEVELS
      for(let i = 0; i < this.levels.length; i++){
        this.levels[i].selected = false;
      }
      this.showInfoEscuela = false;
      this.showInfoEscuela2 = false;
      this.claveEscuela = 'Cargando...';
      this.passEscuela = 'Cargando...';
      this.passEscuela2 = 'Cargando...';
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenInfoOfSchool(name, tipo){
    if(tipo === 'nombre'){
      this.nameOfSchool = name.target.value.toUpperCase();
      this.nameOfSchool2 = name.target.value.toUpperCase();
    } else if(tipo === 'pais'){
      this.countryOfSchool = name.target.value.toUpperCase();
      this.countryOfSchool2 = name.target.value.toUpperCase();
    } else if(tipo === 'estado'){
      this.stateOfSchool = name.target.value.toUpperCase();
      this.stateOfSchool2 = name.target.value.toUpperCase();
    } else if(tipo === 'ciudad'){
      this.cityOfSchool = name.target.value.toUpperCase();
      this.cityOfSchool2 = name.target.value.toUpperCase();
    }
  }

  listenIdOfSchool(id){
    this.id2 = id.target.value;
  }

  // openAddModal(){
  //   this.modal.open(this.addModal);
  // }

  hideAllModals(){
    this.modal.dismissAll();
    this.showInfoEscuela = false;
    this.showInfoEscuela2 = false;
    this.claveEscuela = '';
    this.passEscuela = '';
  }

  seleccionarNivel(positionLevel){
    this.levels[positionLevel].selected = !this.levels[positionLevel].selected;
  }

  mostrarEscuela(idSchool, nombreSchool, ciudadSchool, estadoSchool, paisSchool, seccionesSchool, posicionSchool){
    this.id = idSchool;
    this.nameOfSchool2 = nombreSchool;
    this.nameOfSchool3 = nombreSchool;
    this.cityOfSchool2 = ciudadSchool;
    this.cityOfSchool3 = ciudadSchool;
    this.stateOfSchool2 = estadoSchool;
    this.stateOfSchool3 = estadoSchool;
    this.countryOfSchool2 = paisSchool;
    this.countryOfSchool3 = paisSchool;
    this.sectionsOfSchool = seccionesSchool;
    // ASIGNAR SELECCIONADOS
    for(let i = 0; i < this.sectionsOfSchool.length;i++){
      for(let k = 0; k < this.levels.length; k++){
        if(this.sectionsOfSchool[i].id === this.levels[k].id){
          this.levels[k].selected = true;
        }
      }
    }
    this.position = posicionSchool;
  }

  agregarEscuela(){
    const secciones = this.levels;

    if(this.nameOfSchool.trim() !== '' && this.countryOfSchool.trim() !== ''
    && this.stateOfSchool.trim() !== '' && this.cityOfSchool.trim() !== ''){
      let seleccionoUnNivel = false;
      let contador = 0;
      for(let i = 0; i < secciones.length; i++){
        contador = contador + 1;
        if(secciones[i].selected === true){
          seleccionoUnNivel = true;
        }
      }
      if(contador === secciones.length){
        if(seleccionoUnNivel){
          this.showInfoEscuela = true;
          let nuevoArregloNiveles = [];
          let contador2 = 0;
          for(let i = 0; i < secciones.length; i++){
            contador2 = contador2 + 1;
            if(secciones[i].selected){
              nuevoArregloNiveles.push({
                id: secciones[i].id
              });
            }
          }
          if(contador2 === secciones.length){
            // Hacer petición
            let idRespuesta;
            let passEsc;
            this.notify.showInfo('Procesando solicitud...', 'Escuelas');
            this.api.agregarUnaEscuela(this.token, this.nameOfSchool, nuevoArregloNiveles, this.cityOfSchool, this.stateOfSchool, this.countryOfSchool).subscribe(respuesta => {
              idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
              passEsc = JSON.parse(JSON.stringify(respuesta)).pass;
            }, (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                //console.log('Client-side error occured.');
              } else {
                //console.log('Server-side error occured.');
              }
              if (err.status === 400) {
                this.notify.showError('Error 400', 'Escuelas');
              }
              if (err.status === 401 || err.status === 404) {
                this.auth.eliminarToken();
                this.router.navigate(['', 'auth']);
              }
              if (err.status === 403) {
                this.notify.showWarning('Intentelo de nuevo en 1 minuto', 'Escuelas');
              }
              if (err.status === 500) {
                this.notify.showError('Error 500', 'Escuelas');
              }
            }, () => {
              this.notify.showInfo('Se agregó la escuela correctamente', 'Escuelas');
              this.schools.push({
                id: idRespuesta,
                nombre: this.nameOfSchool,
                pais: this.countryOfSchool,
                estado: this.stateOfSchool,
                ciudad: this.cityOfSchool,
                utilizado: 0
              });
              this.schools.sort(function(a, b){
                if(a.id < b.id) { return -1; }
              });
              this.claveEscuela = idRespuesta;
              this.passEscuela = passEsc;
              // resetear varuables seleccionados
              for(let i = 0; i < this.levels.length; i++){
                this.levels[i].selected = false;
              }
            });
          }
        } else {
          this.notify.showWarning('Debes seleccionar al menos un nivel académico', 'Escuelas');
        }
      }
    } else {
      if(this.nameOfSchool.trim() === ''){
        this.notify.showWarning('Debes agregar un nombre', 'Escuelas');
      }
      if(this.countryOfSchool.trim() === ''){
        this.notify.showWarning('Debes asignar un pais', 'Escuelas');
      }
      if(this.stateOfSchool.trim() === ''){
        this.notify.showWarning('Debes asignar un estado', 'Escuelas');
      }
      if(this.cityOfSchool.trim() === ''){
        this.notify.showWarning('Debes asignar una ciudad', 'Escuelas');
      }
    }
  }

  actualizarEscuela(){
    const secciones = this.levels;

    if(this.nameOfSchool2.trim() !== '' && this.countryOfSchool2.trim() !== ''
    && this.stateOfSchool2.trim() !== '' && this.cityOfSchool2.trim() !== ''){
      let seleccionoUnNivel = false;
      let contador = 0;
      for(let i = 0; i < secciones.length; i++){
        contador = contador + 1;
        if(secciones[i].selected === true){
          seleccionoUnNivel = true;
        }
      }
      if(contador === secciones.length){
        if(seleccionoUnNivel){
          this.showInfoEscuela = true;
          let nuevoArregloNiveles = [];
          let contador2 = 0;
          for(let i = 0; i < secciones.length; i++){
            contador2 = contador2 + 1;
            if(secciones[i].selected){
              nuevoArregloNiveles.push({
                id: secciones[i].id
              });
            }
          }
          if(contador2 === secciones.length){
            // Hacer petición
            this.api.actualizarUnaEscuela(this.token, this.id ,this.nameOfSchool2, nuevoArregloNiveles, this.cityOfSchool2, this.stateOfSchool2, this.countryOfSchool2).subscribe(respuesta => {
            }, (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                //console.log('Client-side error occured.');
              } else {
                //console.log('Server-side error occured.');
              }
              if (err.status === 400) {
                this.notify.showError('Error 400', 'Escuelas');
              }
              if (err.status === 401 || err.status === 404) {
                this.auth.eliminarToken();
                this.router.navigate(['', 'auth']);
              }
              if (err.status === 403) {
                this.notify.showWarning('La escuela que intentas actualizar no existe', 'Escuelas');
              }
              if (err.status === 500) {
                this.notify.showError('Error 500', 'Escuelas');
              }
            }, () => {
              this.notify.showInfo('Se actualizó la escuela correctamente', 'Escuelas');
              this.schools[this.position].nombre = this.nameOfSchool2;
              this.schools[this.position].pais = this.countryOfSchool2;
              this.schools[this.position].estado = this.stateOfSchool2;
              this.schools[this.position].ciudad = this.cityOfSchool2;
              this.schools[this.position].nombre = this.nameOfSchool2;
              this.schools[this.position].secciones = nuevoArregloNiveles;
              // resetear varuables seleccionados
              for(let i = 0; i < this.levels.length; i++){
                this.levels[i].selected = false;
              }
            });
          }
        } else {
          this.notify.showWarning('Debes seleccionar al menos un nivel académico', 'Escuelas');
        }
      }
    } else {
      if(this.nameOfSchool2.trim() === ''){
        this.notify.showWarning('Debes agregar un nombre', 'Escuelas');
      }
      if(this.countryOfSchool2.trim() === ''){
        this.notify.showWarning('Debes asignar un pais', 'Escuelas');
      }
      if(this.stateOfSchool2.trim() === ''){
        this.notify.showWarning('Debes asignar un estado', 'Escuelas');
      }
      if(this.cityOfSchool2.trim() === ''){
        this.notify.showWarning('Debes asignar una ciudad', 'Escuelas');
      }
    }
  }

  renovarPassEscuela(){
    this.showInfoEscuela2 = true;
    this.notify.showInfo('Procesando solicitud...', 'Escuelas');
    let passEsc;
    this.api.renovarPassEscuela(this.token, this.id ).subscribe(respuesta => {
      passEsc = JSON.parse(JSON.stringify(respuesta)).pass;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError('Error 400', 'Escuelas');
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 403) {
        this.notify.showWarning('La escuela que intentas actualizar no existe', 'Escuelas');
      }
      if (err.status === 500) {
        this.notify.showError('Error 500', 'Escuelas');
      }
    }, () => {
      this.passEscuela2 = passEsc;
      this.notify.showInfo('Se renovó la contraseña correctamente', 'Escuelas');
    });
  }

  eliminarEscuela(){
    if(this.id2.trim() !== this.id.trim()){
      this.notify.showWarning('Los ID no coinciden', 'Escuelas');
    } else {
      this.notify.showInfo('Procesando solicitud...', 'Escuelas');
      this.api.eliminarUnaEscuela(this.token, this.id ).subscribe(respuesta => {
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Escuelas');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('La escuela que intentas eliminar cuenta con grupos activos o no existe', 'Escuelas');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Escuelas');
        }
      }, () => {
        this.notify.showInfo('La escuela se eliminó correctamente', 'Escuelas');
        this.schools.splice(this.position, 1);
        this.hideAllModals();
      });
    }
  }

}
