import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SafeHtmlPipe } from 'src/app/pipes/safehtml/safe-html.pipe';

@Component({
  selector: 'app-view-page-of-a-topic',
  templateUrl: './view-page-of-a-topic.component.html',
  styleUrls: ['./view-page-of-a-topic.component.css']
})
export class ViewPageOfATopicComponent implements OnInit {

  private token = '';
  public infoPage;
  public heightModal = 0;
  constructor(private router: Router, private auth: AuthServiceService) { 
    this.heightModal = window.innerHeight;
    // console.log("HEIGHT: ", this.heightModal);
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(tokenEspirado){
      this.router.navigate(['', 'auth']);
    } else {
      if(info.permisos[0].actualizar_paginas === true){
        if(localStorage.getItem('detallePagina4') !== null){
          this.infoPage = JSON.parse(localStorage.getItem('detallePagina4'));
          // console.log("INFO VISUALIZAR PAGINA: ", this.infoPage);
          //console.log("INFO PAGE DESPUÉS 2: ", this.infoPage);
        } else {
          this.router.navigate(['', 'books', 'pages']);
        }
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  ngOnInit() {
  }

}
