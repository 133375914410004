import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from "@angular/forms";
import { environment } from '../../../../../environments/environment.prod';

@Component({
  selector: 'app-multimedia-videos',
  templateUrl: './multimedia-videos.component.html',
  styleUrls: ['./multimedia-videos.component.css']
})
export class MultimediaVideosComponent implements OnInit {

  public id = '';
  public id2 = '';
  private position = 0;
  public descriptionOfVideo = '';
  public descriptionOfVideo2 = '';
  private descriptionOfVideo3 = '';
  public videos = [];
  public permisoVisualizar = false;
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  opciones: NgbModalOptions;
  resultado: string;
  listadoDeArchivos = [];
  listadoDeArchivos2 = [];
  listadoDeVideos = [];
  listadoDeObjetos = [];
  formulario: FormGroup;
  barraDeProgreso: number = 0;
  public inhabilitarElBoton = false;
  public enProceso = false;
  private token = '';
  private urlBucket = 'https://www.jenios.mx';
  public seleccionarVideo = false;
  private infoPage;

  public configuracion: DropzoneConfigInterface = {
    clickable: true,
    url: 'https://jenios.mx',
    paramName: 'file',
    maxFiles: 10,
    maxFilesize: 100,
    dictFileTooBig: 'El video es más grande de 100MB',
    uploadMultiple: true,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    acceptedFiles: 'image/png,image/jpg,image/jpeg,image/gif'
  };

  constructor(private modal: NgbModal,
    private notify: ToastNotificationService,
    private api: RestfulApiService,
    private auth: AuthServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public formBuilder: FormBuilder,) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    this.formulario = this.formBuilder.group({
      file: [null]
    });
  }


  ngOnInit() {
    if (this.route.snapshot.queryParams.agregando !== undefined) {
      this.seleccionarVideo = this.route.snapshot.queryParams.agregando;
    } else if (this.route.snapshot.queryParams.editando !== undefined) {
      this.seleccionarVideo = this.route.snapshot.queryParams.editando;
    } else {
      this.seleccionarVideo = false;
    }
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if (!tokenEspirado && this.token !== null) {
      this.permisoVisualizar = info.permisos[0].visualizar_videos;
      this.permisoAgregar = info.permisos[0].agregar_imagenes;
      this.permisoEditar = info.permisos[0].actualizar_imagenes;
      this.permisoEliminar = info.permisos[0].eliminar_imagenes;
      if (info.permisos[0].visualizar_imagenes === true) {
        // this.api.obtenerEnlaceJeniosBucket().subscribe(respuesta => {
        //   this.urlBucket = JSON.parse(JSON.stringify(respuesta)).url;
        // }, (err: HttpErrorResponse) => {
        //   if (err.error instanceof Error) {
        //     //console.log('Client-side error occured.');
        //   } else {
        //     //console.log('Server-side error occured.');
        //   }
        //   if (err.status === 400) {
        //     this.notify.showError("Error 400", 'VIDEOS');
        //   }
        //   if (err.status === 401 || err.status === 404) {
        //     this.auth.eliminarToken();
        //     this.router.navigate(['', 'auth']);
        //   }
        //   if (err.status === 500) {
        //     this.notify.showError("Error 500", 'VIDEOS');
        //   }
        // });
        let listado;
        this.api.obtenerListadoVideos(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).videos;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", 'VIDEOS');
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", 'VIDEOS');
          }
        }, () => {
          // console.log("VIDEOS: ", listado);
          this.videos = listado;
          // console.log("VIDEOS: ", this.videos);
          for (let i = 0; i < this.videos.length; i++) {
            const nombreConExtension = `${this.videos[i].id}${this.videos[i].src.substring(this.videos[i].src.length - 4, this.videos[i].src.length)}`;
            const extensionFile = nombreConExtension.substring(nombreConExtension.length - 3, nombreConExtension.length);
            this.videos[i].nombre = nombreConExtension;
            this.videos[i].extension = extensionFile;
          }
          this.notify.showInfo("Información actualizada", 'VIDEOS');
        });
      } else if (info.permisos[0].agregar_imagenes !== true) {
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return `${reason}`;
    }
  }

  subirVideos(e) {
    this.listadoDeArchivos = Array.from(e);
    this.listadoDeArchivos.forEach((item, i) => {
      const file = (e as HTMLInputElement);
      if (file[i].type === 'video/webm' || file[i].type === 'video/mp4') {
        const url = URL.createObjectURL(file[i]);
        this.listadoDeVideos.push(url);
        this.listadoDeArchivos2.push({ item, url: url });
      } else {
        this.notify.showError('error', 'Asegúrate de subir archivos de tipo video');
      }
    });
    this.listadoDeArchivos2.forEach((item) => {
      this.listadoDeObjetos.push(item.item);
    });
    // Set files formulario control
    this.formulario.patchValue({
      file: this.listadoDeObjetos
    });

    this.formulario.get('file').updateValueAndValidity();
  }

  // Clean Url
  limpiarUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  quitarVideo(position) {
    this.listadoDeVideos.splice(position, 1);
    this.listadoDeObjetos.splice(position, 1);
    this.listadoDeArchivos2.splice(position, 1);

    this.formulario.value.file = null;

    // Set files form control
    this.formulario.patchValue({
      file: this.listadoDeObjetos
    });

    this.formulario.get('file').updateValueAndValidity();
  }

  subirMultimedia() {

    // SUBIR IMÁGENES A JENIOS BUCKET
    var listadoImagenes = [];
    this.urlBucket = environment.url_bucket;

    // REMPLAZAR URL POR http://localhost:8443
    this.api.subirMultimedia(this.formulario.value.file, this.urlBucket, 'img')
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            this.notify.showWarning('Por favor espere...', 'Subiendo imágenes');
            this.inhabilitarElBoton = true;
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.barraDeProgreso = Math.round(event.loaded / event.total * 100);
            // console.log(`Uploaded! ${this.barraDeProgreso}%`);
            if (this.barraDeProgreso === 100) {
              this.notify.showInfo('Por favor espere...', 'Procesando imágenes');
              this.enProceso = true;
            }
            break;
          case HttpEventType.Response:
            console.log('File uploaded successfully!', event.body);
            if (event.body.code === 200) {
              const { file, uid } = event.body;

              this.inserVideoOnDB({ file, uid });
              //listadoImagenes = event.body.images;
              this.notify.showSuccess('Se completó la subida de imágenes', 'Felicidades');
              // for (let i = 0; i < listadoImagenes.length; i++) {
              //   this.images.unshift(listadoImagenes[i]);
              //   this.images2.unshift(listadoImagenes[i]);
              // }

              //this.images2.unshift("lkFrvOWZX.jpeg");

            } else {
              this.barraDeProgreso = 0;
              this.notify.showError('Póngase en contacto con el equipo de soporte', 'Ocurrió un problema');
              this.inhabilitarElBoton = false;
            }
        }
      });
  }

  inserVideoOnDB(fileUploaded: any) {

    const { file, uid } = fileUploaded;
    const bucket = this.urlBucket;
    let imageInsertInfo = {
      "en_uso": 0,
      "descripcion": "",
      "uid": `${uid}`,
      "src": `${bucket}/uploads/${file}`
    }

    this.api.insertMultimediaonDB(bucket, imageInsertInfo, "videos")
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            if (event.body.status === 200) {
              console.log('File CREATE successfully!', event.body);
              this.notify.showSuccess('Se ha insertado el Video en DB', 'Felicidades');

              this.videos.push(event.body.details);
              this.inhabilitarElBoton = false;
              this.enProceso = false;
              this.barraDeProgreso = 0;
              this.listadoDeVideos = [];
              this.listadoDeArchivos = [];
              this.listadoDeArchivos2 = [];
              this.listadoDeObjetos = [];

            } else {
              this.notify.showError('Póngase en contacto con el equipo de soporte', 'Ocurrió un problema');
            }
        }
      });
  }

  mostrarInfo(idObject, descriptionObject, posicionObject) {
    this.id = idObject;
    this.descriptionOfVideo2 = descriptionObject;
    this.descriptionOfVideo3 = descriptionObject;
    this.position = posicionObject;
  }

  seleccionaVideo(idObject, descriptionObject, posicionObject) {
    this.id = idObject;
    this.descriptionOfVideo2 = descriptionObject;
    this.descriptionOfVideo3 = descriptionObject;
    this.position = posicionObject;

    if (this.seleccionarVideo) {
      this.infoPage = JSON.parse(localStorage.getItem('detallePagina2'));
      if (this.infoPage.videos === null) {
        this.infoPage.videos = [];
      }
      this.infoPage.videos.push({
        id: idObject,
        src: this.videos[posicionObject].src,
        descripcion: descriptionObject
      });
      this.infoPage.contenido = `${this.infoPage.contenido} #videoid='V${idObject}'#`;
      if (this.route.snapshot.queryParams.agregando === 'true') {
        this.router.navigate(['', 'books', 'pages', 'add']);
        localStorage.setItem('detallePagina3', JSON.stringify(this.infoPage));
        localStorage.removeItem('detallePagina2');
      } else if (this.route.snapshot.queryParams.editando === 'true') {
        this.router.navigate(['', 'books', 'pages', 'edit']);
        localStorage.setItem('detallePagina', JSON.stringify(this.infoPage));
        localStorage.removeItem('detallePagina2');
      }
    }
  }

  listenDescription(name) {
    this.descriptionOfVideo = name.target.value;
    this.descriptionOfVideo2 = name.target.value;
  }

  listenId(id) {
    this.id2 = id.target.value;
  }

  actualizarVideo() {
    if (this.descriptionOfVideo2.trim() !== this.descriptionOfVideo3.trim()) {
      this.peticionActualizar();
    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', 'VIDEOS');
    }
  }

  peticionActualizar() {
    if (this.descriptionOfVideo2.trim() !== '') {
      let estadoRespuesta;
      this.api.actualizarUnVideo(this.token, this.id, this.descriptionOfVideo2.trim()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'VIDEOS');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro video. Prueba con otro diferente', 'VIDEOS');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'VIDEOS');
        }
      }, () => {
        if (estadoRespuesta === 200) {
          this.notify.showInfo('Se actualizó el video', 'VIDEOS');
          this.videos[this.position].descripcion = this.descriptionOfVideo2.trim();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'VIDEOS');
        }
      });
    } else {
      if (this.descriptionOfVideo2.trim() === '') {
        this.notify.showWarning('No puedes actualizar un video sin descripción', 'VIDEOS');
      }
    }
  }

  eliminarVideo() {
    if (this.id2.trim() === this.id.trim()) {
      let estadoRespuesta;
      this.api.eliminarUnVideo(this.id, this.videos[this.position].farm, this.videos[this.position].server).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).status;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'VIDEOS');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El video que intentas eliminar no existe', 'VIDEOS');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'VIDEOS');
        }
      }, () => {
        if (estadoRespuesta === 200) {
          this.videos.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el video', 'VIDEOS');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'VIDEOS');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'VIDEOS');
    }
  }

}
