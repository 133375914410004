import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-multimedia-galleries',
  templateUrl: './multimedia-galleries.component.html',
  styleUrls: ['./multimedia-galleries.component.css']
})
export class MultimediaGalleriesComponent implements OnInit {

  @ViewChild('add', {static: true}) addModal: TemplateRef<any>;
  @ViewChild('edit', {static: true}) editModal: TemplateRef<any>;

  public id = '';
  private id2 = '';
  private position = 0;
  public descriptionOfGalleries = '';
  public descriptionOfGalleries2 = '';
  private descriptionOfGalleries3 = '';
  public galerias = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  public imagenesGaleria = [];
  public descripcionGaleria = '';
  public seleccionarGaleria = false;
  public infoGaleria = {
    imagenes: [],
    descripcion: ''
  };
  private infoPage;
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router, private route: ActivatedRoute) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    if(this.route.snapshot.queryParams.agregando !== undefined){
      this.seleccionarGaleria = this.route.snapshot.queryParams.agregando;
    } else if(this.route.snapshot.queryParams.editando !== undefined){
      this.seleccionarGaleria = this.route.snapshot.queryParams.editando;
    } else {
      this.seleccionarGaleria = false;
    }
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_galerias;
      this.permisoEditar = info.permisos[0].actualizar_galerias;
      this.permisoEliminar = info.permisos[0].eliminar_galerias;
      if(info.permisos[0].visualizar_galerias === true){
        let listado;
        this.api.obtenerListadoGalerias(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).galerias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", 'GALERÍAS');
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", 'GALERÍAS');
          }
        }, () => {
          this.galerias = listado;
          this.notify.showInfo("Información actualizada", 'GALERÍAS');
        });
        if(localStorage.getItem('detalleGalerias2') !== null){
          this.infoGaleria = JSON.parse(localStorage.getItem('detalleGalerias2'));
          // console.log("INFO GALERIA ON INIT: ", this.infoGaleria);
          this.imagenesGaleria = this.infoGaleria.imagenes;
          this.descriptionOfGalleries = this.infoGaleria.descripcion;
          this.openAddModal();
        }
      } else if(info.permisos[0].agregar_galerias !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  mostratInfo(id, descripcion, position){
    this.id = id;
    this.descriptionOfGalleries2 = descripcion;
    this.descriptionOfGalleries3 = descripcion;
    this.position = position;
  }

  visualizarGaleria(imagenes, descripcion){
    this.imagenesGaleria = imagenes;
    this.descripcionGaleria = descripcion;
  }

  openAddModal(){
    this.modal.open(this.addModal);
  }

  hideAllModals(){
    this.modal.dismissAll()
  }

  openEditModal(){
    this.modal.open(this.editModal);
  }

  seleccionaImagenes(){
    localStorage.removeItem('detalleGalerias');
    this.infoGaleria.imagenes = this.imagenesGaleria;
    // console.log("INFO GALERIA: ", this.infoGaleria);
    localStorage.setItem('detalleGalerias', JSON.stringify(this.infoGaleria));
    this.router.navigate(['', 'multimedia', 'images'], { queryParams: { galeria: 'true' } });
  }

  quitarImagenDeGaleria(position){
    this.imagenesGaleria.splice(position, 1);
    if(this.imagenesGaleria.length === 0){
      localStorage.removeItem('detalleGalerias2');
    } else {
      localStorage.setItem('detalleGalerias2', JSON.stringify(this.imagenesGaleria));
    }
  }

  listenDescription(name){
    this.descriptionOfGalleries = name.target.value;
    this.descriptionOfGalleries2 = name.target.value;
    this.infoGaleria.descripcion = name.target.value;
  }

  listenId(id){
    this.id2 = id.target.value;
  }

  agregarGaleria(){
    let idRespuesta;
    this.api.agregarUnaGaleria(this.token, this.imagenesGaleria, this.descriptionOfGalleries.trim()).subscribe(respuesta => {
      idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError('Error 400', 'GALERÍAS');
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 403) {
        this.notify.showWarning('El nombre ya existe en otra galería. Prueba con otro diferente', 'GALERÍAS');
      }
      if (err.status === 500) {
        this.notify.showError('Error 500', 'GALERÍAS');
      }
    }, () => {
      this.notify.showInfo('Se agregó la galería correctamente', 'GALERÍAS');
      this.galerias.push({
        id: idRespuesta,
        descripcion: this.descriptionOfGalleries.trim(),
        en_uso: 0,
        imagenes: this.imagenesGaleria
      });
      //console.log("IMAGENES: ", this.imagenesGaleria);
      this.imagenesGaleria = [];
      localStorage.removeItem('detalleGalerias2');
      this.hideAllModals();
    });
  }

  actualizarGaleria(){
    if(this.descriptionOfGalleries2.trim() !== this.descriptionOfGalleries3.trim()){
      let idRespuesta;
      this.api.actualizarUnaGaleria(this.token, this.id, this.descriptionOfGalleries2.trim()).subscribe(respuesta => {
        idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'GALERÍAS');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otra galería. Prueba con otro diferente', 'GALERÍAS');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'GALERÍAS');
        }
      }, () => {
        this.notify.showInfo('Se actualizó la galería correctamente', 'GALERÍAS');
        this.galerias[this.position].descripcion = this.descriptionOfGalleries2.trim();
        this.hideAllModals();
      });
    } else {
      this.notify.showWarning('Las descripciones no pueden ser los mismos', 'GALERÍAS');
    }
  }

  eliminarGaleria(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnaGaleria(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'IMÁGENES');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('La galería que intentas eliminar no existe', 'GALERÍAS');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'IMÁGENES');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.galerias.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente la galería', 'GALERÍAS');
          this.hideAllModals();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'GALERÍAS');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'GALERÍAS');
    }
  }

  seleccionaGaleria(idObject, descriptionObject, posicionObject){
    this.id = idObject;
    this.position = posicionObject;

    if(this.seleccionarGaleria){
      this.infoPage = JSON.parse(localStorage.getItem('detallePagina2'));
      if(this.infoPage.galerias === null){
        this.infoPage.galerias = [];
      }
      this.infoPage.galerias.push({
        id: idObject,
        // src: this.galerias[posicionObject].src,
        imagenes: this.galerias[posicionObject].imagenes,
        descripcion: descriptionObject
      });
      this.infoPage.contenido = `${this.infoPage.contenido} #galeriaid='G${idObject}'#`;
      if(this.route.snapshot.queryParams.agregando === 'true'){
        this.router.navigate(['', 'books', 'pages', 'add']);
        localStorage.removeItem('detallePagina2');
        localStorage.setItem('detallePagina3', JSON.stringify(this.infoPage));
      } else if(this.route.snapshot.queryParams.editando === 'true'){
        this.router.navigate(['', 'books', 'pages', 'edit']);
        localStorage.removeItem('detallePagina2');
        localStorage.setItem('detallePagina', JSON.stringify(this.infoPage));
      }
    }
  }

}
