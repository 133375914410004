import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';

@Component({
  selector: 'app-class-list',
  templateUrl: './class-list.component.html',
  styleUrls: ['./class-list.component.css']
})
export class ClassListComponent implements OnInit, OnDestroy {

  public idEscuela = '';
  public idGrupo = '';
  private idClase = '';
  public nombreClase = '';
  public nombreGrupo = '';
  public idTeacher = ''; 
  private positionClase = 0;
  public permisoSeleccionar = false;
  public permisoLiberar = false;
  public clases = [];
  public docentes = [];
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) { 
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.idTeacher = '';
    const idEscuela = sessionStorage.getItem('idEscuela');
    const idGrupo = sessionStorage.getItem('idGrupo');
    const nombreGrupo = sessionStorage.getItem('nombreGrupo');
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoSeleccionar = info.permisos[0].seleccionar_clases;
      this.permisoLiberar = info.permisos[0].liberar_clases;
      this.idEscuela = idEscuela;
      this.idGrupo = idGrupo;
      this.nombreGrupo = nombreGrupo;
      if(info.permisos[0].visualizar_clases === true){
        // this.router.navigate(['', 'auth']);
        if(idEscuela !== null && idGrupo !== null && nombreGrupo !== null){
          this.clases = [];
          let listado;
          this.api.consultarClases(this.token, this.idEscuela.trim(), this.idGrupo.trim()).subscribe(respuesta => {
            listado = JSON.parse(JSON.stringify(respuesta)).clases;
          }, (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              //console.log('Client-side error occured.');
            } else {
              //console.log('Server-side error occured.');
            }
            if (err.status === 400) {
              this.notify.showError("Error 400", "Clases");
            }
            if (err.status === 401 || err.status === 404) {
              this.auth.eliminarToken();
              this.router.navigate(['', 'auth']);
            }
            if (err.status === 500) {
              this.notify.showError("Error 500", "Clases");
            }
          }, () => {
            this.clases = listado;
            this.clases.sort(function(a, b){
              if(a.titulo_clase < b.titulo_clase) { return -1; }
            });
            this.notify.showInfo("Información actualizada", "Clases");
          });
        } else {
          this.router.navigate(['', 'admin', 'groups']);
        }
      } if(info.permisos[0].visualizar_maestros === true){
        let listado2;
        this.api.obtenerListadoMaestros2(this.token, this.idEscuela).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).teachers;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Maestros");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Maestros");
          }
        }, () => {
          this.docentes = listado2;
          this.docentes.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
        });
      } else if(info.permisos[0].seleccionar_clases !== true){
        this.router.navigate(['', 'auth']);
      } else if(info.permisos[0].liberar_clases !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  mostrarInfoClase(idEsc, idGrupo, idClase, nombreClase, idTeacher, positionClase){
    this.positionClase = positionClase;
    this.idEscuela = idEsc;
    this.idGrupo = idGrupo;
    this.idClase = idClase;
    this.nombreClase = nombreClase;
    this.idTeacher = idTeacher;
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      //console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  hideAllModals(){
    this.modal.dismissAll();
    this.idEscuela = '';
    this.idGrupo = '';
    this.idClase = '';
    this.idTeacher = ''; 
    this.nombreClase = '';
  }

  asignarClase(){
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    if(!tokenEspirado && this.token !== null){
      let estadoRes;
      this.api.asignarUnaClase(this.token, this.idEscuela.trim(), this.idGrupo.trim(), this.idClase.trim(), this.idTeacher).subscribe(respuesta => {
        estadoRes = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError("Error 400", "Clases");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 500) {
          this.notify.showError("Error 500", "Clases");
        }
      }, () => {
        if(estadoRes === 200){
          this.clases[this.positionClase].maestro = this.idTeacher;
          this.hideAllModals();
          this.notify.showInfo('Se asignó la clase exitosamente', 'Clases');
        } else {
          this.notify.showError("Ocurrió un error inesperado", "Clases");
        }
      });
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  liberarClase(){
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    if(!tokenEspirado && this.token !== null){
      let estadoRes;
      this.api.liberarUnaClase(this.token, this.idEscuela.trim(), this.idGrupo.trim(), this.idClase.trim(), this.idTeacher).subscribe(respuesta => {
        estadoRes = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError("Error 400", "Clases");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 500) {
          this.notify.showError("Error 500", "Clases");
        }
      }, () => {
        if(estadoRes === 200){
          this.clases[this.positionClase].maestro = '';
          this.hideAllModals();
          this.notify.showInfo('Se liberó la clase exitosamente', 'Clases');
        } else {
          this.notify.showError("Ocurrió un error inesperado", "Clases");
        }
      });
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  ngOnDestroy(){
    sessionStorage.removeItem('idEscuela');
    sessionStorage.removeItem('idGrupo');
  }

}
