import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-blocks-of-a-subject',
  templateUrl: './list-of-blocks-of-a-subject.component.html',
  styleUrls: ['./list-of-blocks-of-a-subject.component.css']
})
export class ListOfBlocksOfASubjectComponent implements OnInit, DoCheck {

  public id = '';
  public id2 = '';
  public materiaSeleccionada = '';
  public materiaSeleccionada2 = '';
  public nombreMateriaSeleccionada = '';
  public titleBlock = '';
  public titleBlock2 = '';
  public titleBlock3 = '';
  private position = 0;
  public subjects = [];
  public blocks = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_bloques;
      this.permisoEditar = info.permisos[0].actualizar_bloques;
      this.permisoEliminar = info.permisos[0].eliminar_bloques;
      if(info.permisos[0].visualizar_bloques === true){
        let listado;
        this.api.obtenerListadoMaterias(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).materias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.subjects = listado;
        });
        let listado2;
        this.api.obtenerListadoBloques(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).bloques;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Bloques");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Bloques");
          }
        }, () => {
          this.blocks = listado2;
          this.notify.showInfo("Información actualizada", "Bloques");
        });
      } else if(info.permisos[0].agregar_bloques !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  ngDoCheck(){
    for(let k = 0; k < this.blocks.length; k++){
      for(let i = 0; i < this.subjects.length; i++){
        if(this.subjects[i].id === this.blocks[k].materia){
          this.blocks[k].tituloMateria = this.subjects[i].titulo;
        }
      }
    }
    this.blocks.sort(function(a, b){
      if(a.tituloMateria < b.tituloMateria) { return -1; }
    });
  }

  open(content) {
    this.titleBlock = '';
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenNameOfBlock(name){
    name.target.value = name.target.value.toUpperCase();
    this.titleBlock = name.target.value;
    this.titleBlock2 = name.target.value;
  }

  listenIdOfBlock(id){
    this.id2 = id.target.value;
  }

  mostrarBloque(idBlock, nombreBlock, idMateria, posicionBloque){
    this.id = idBlock;
    this.titleBlock2 = nombreBlock;
    this.titleBlock3 = nombreBlock;
    this.materiaSeleccionada2 = idMateria;
    this.position = posicionBloque;
    this.nombreMateriaSeleccionada = this.filtrarMaterias(this.materiaSeleccionada2)[0].titulo;
  }

  agregarBloque(){
    const infoMateria = this.filtrarMaterias(this.materiaSeleccionada)[0];
    let idRespuesta;
    this.api.agregarUnBloque(this.token, infoMateria.seccion, infoMateria.grado, infoMateria.id, this.titleBlock).subscribe(respuesta => {
      idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError('Error 400', 'Bloques');
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 403) {
        this.notify.showWarning('El nombre ya existe en otro grado. Prueba con otro diferente', 'Bloques');
      }
      if (err.status === 500) {
        this.notify.showError('Error 500', 'Bloques');
      }
    }, () => {
      this.blocks.push({
        id: idRespuesta,
        materia: this.materiaSeleccionada,
        titulo: this.titleBlock,
        utilizado: 0
      });
      this.materiaSeleccionada = '';
      this.titleBlock = '';
      this.notify.showInfo('Se agregó el bloque correctamente', 'Bloques');
    });
  }
  
  actualizarBloque(){
    if(this.titleBlock2.trim() !== this.titleBlock3.trim()){
      let estadoRespuesta;
      this.api.actualizarUnBloque(this.token, this.id, this.titleBlock2).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Bloques');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El bloque que intentas actualizar no existe', 'Bloques');
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro bloque. Prueba con otro diferente', 'Bloques');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Bloques');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.notify.showInfo('Se actualizó el bloque correctamente', 'Bloques');
          this.blocks[this.position].titulo = this.titleBlock2.trim();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Bloques');
        }
      });

    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', 'Bloques');
    }
  }

  eliminarBloque(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnBloque(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Bloques');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro bloque. Prueba con otro diferente', 'Bloques');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Bloques');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.blocks.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el bloque', 'Bloques');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Bloques');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'Bloques');
    }
  }



  filtrarMaterias(key: string ) {
    return this.subjects.filter(object => {
      return object.id === key;
    });
  }

}
