import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-quizzes',
  templateUrl: './list-of-quizzes.component.html',
  styleUrls: ['./list-of-quizzes.component.css']
})
export class ListOfQuizzesComponent implements OnInit {

  public quizzes = [];
  private token = '';

  constructor(private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) { }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      if(info.permisos[0].visualizar_quizzes === true){
        // let listado;
        // this.api.obtenerlis(this.token).subscribe(respuesta => {
        //   listado = JSON.parse(JSON.stringify(respuesta)).wikis;
        // }, (err: HttpErrorResponse) => {
        //   if (err.error instanceof Error) {
        //     //console.log('Client-side error occured.');
        //   } else {
        //     //console.log('Server-side error occured.');
        //   }
        //   if (err.status === 400) {
        //     this.notify.showError("Error 400", 'WIKIS');
        //   }
        //   if (err.status === 401 || err.status === 404) {
        //     this.auth.eliminarToken();
        //     this.router.navigate(['', 'auth']);
        //   }
        //   if (err.status === 500) {
        //     this.notify.showError("Error 500", 'WIKIS');
        //   }
        // }, () => {
        //   this.wikis = listado;
        //   this.notify.showInfo("Información actualizada", 'WIKIS');
        // });
      } else {
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

}
