import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, DoCheck, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-school-subjects',
  templateUrl: './list-of-school-subjects.component.html',
  styleUrls: ['./list-of-school-subjects.component.css']
})
export class ListOfSchoolSubjectsComponent implements OnInit, DoCheck {

  @ViewChild('add', {static: true}) addModal: TemplateRef<any>;
  @ViewChild('edit', {static: true}) editModal: TemplateRef<any>;
  
  public seccionSeleccionada = '';
  public seccionSeleccionada2 = '';
  public nombreSeccionSeleccionada = '';
  public gradoSeleccionado = '';
  public gradoSeleccionado2 = '';
  public esAmbito = true;
  public esAmbito2 = true;
  public id = '';
  private id2 = '';
  private position = 0;
  public titleOfSubject = '';
  public titleOfSubject2 = '';
  public imgOfSubject = '';
  public imgOfSubject2 = '';
  public priceOfSubject = 0;
  public priceOfSubject2 = 0;
  public descriptionOfSubject = '';
  public descriptionOfSubject2 = '';
  public sections = [];
  private grades = [];
  public grades2 = [];
  public subjects = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  private infoMateria = {
    id: '',
    section: '',
    grade: '',
    esAmbito: true,
    titulo: '',
    portada: '',
    precio: 0,
    descripcion: ''
  };
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_materias;
      this.permisoEditar = info.permisos[0].actualizar_materias;
      this.permisoEliminar = info.permisos[0].eliminar_materias;
      if(info.permisos[0].visualizar_materias === true){
        if(localStorage.getItem('infoMateria2') !== null){
          this.infoMateria = JSON.parse(localStorage.getItem('infoMateria2'));
          this.esAmbito = this.infoMateria.esAmbito;
          this.titleOfSubject = this.infoMateria.titulo;
          this.imgOfSubject = this.infoMateria.portada;
          this.priceOfSubject = this.infoMateria.precio;
          this.descriptionOfSubject = this.infoMateria.descripcion;
          this.openAddModal();
        }
        if(localStorage.getItem('infoMateria3') !== null){
          this.infoMateria = JSON.parse(localStorage.getItem('infoMateria3'));
          this.id = this.infoMateria.id;
          this.esAmbito2 = this.infoMateria.esAmbito;
          this.titleOfSubject2 = this.infoMateria.titulo;
          this.imgOfSubject2 = this.infoMateria.portada;
          this.priceOfSubject2 = this.infoMateria.precio;
          this.descriptionOfSubject2 = this.infoMateria.descripcion;
          this.openEditModal();
        }
        let listado;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.sections = listado;
        });
        let listado2;
        this.api.obtenerListadoGrados(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).grados;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Grados");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Grados");
          }
        }, () => {
          this.grades = listado2;
          if(localStorage.getItem('infoMateria2') !== null){
            this.seccionSeleccionada = this.infoMateria.section;
            this.seleccionarNivelAcademico();
            this.gradoSeleccionado = this.infoMateria.grade;
            localStorage.removeItem('infoMateria2');
          }
          if(localStorage.getItem('infoMateria3') !== null){
            this.seccionSeleccionada = this.infoMateria.section;
            this.seccionSeleccionada2 = this.infoMateria.section;
            this.seleccionarNivelAcademico();
            this.gradoSeleccionado2 = this.infoMateria.grade;
            localStorage.removeItem('infoMateria3');
          }
        });
        let listado3;
        this.api.obtenerListadoMaterias(this.token).subscribe(respuesta => {
          listado3 = JSON.parse(JSON.stringify(respuesta)).materias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Materias");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Materias");
          }
        }, () => {
          this.subjects = listado3;
          this.notify.showInfo("Información actualizada", "Materias");
        });
      } else if(info.permisos[0].agregar_materias !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  ngDoCheck(){
    for(let k = 0; k < this.subjects.length; k++){
      for(let i = 0; i < this.sections.length; i++){
        if(this.sections[i].id === this.subjects[k].seccion){
          this.subjects[k].nombreSeccion = this.sections[i].nombre;
        }
      }
    }
    this.subjects.sort(function(a, b){
      if(a.nombreSeccion < b.nombreSeccion) { return -1; }
      // if(a.firstname > b.firstname) { return 1; }
      // return 0;
    }); 
  }

  open(content) {
    // Restablecer valores
    this.titleOfSubject = '';
    this.imgOfSubject = '';
    this.priceOfSubject = 0;
    this.descriptionOfSubject = '';
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenTitleOfSubject(name){
    name.target.value = name.target.value.toUpperCase();
    this.titleOfSubject = name.target.value;
    this.titleOfSubject2 = name.target.value;
  }

  listenUrlImgOfSubject(name){
    name.target.value = name.target.value;
    this.imgOfSubject = name.target.value;
    this.imgOfSubject2 = name.target.value;
  }

  listenPriceOfSubject(name){
    name.target.value = name.target.value;
    this.priceOfSubject = name.target.value;
    this.priceOfSubject2 = name.target.value;
  }

  listenDescripcionOfSubject(name){
    name.target.value = name.target.value;
    this.descriptionOfSubject = name.target.value;
    this.descriptionOfSubject2 = name.target.value;
  }


  listenIdOfSubject(id){
    this.id2 = id.target.value;
  }

  mostrarMateria(idMateria, idSeccion, idGrado, tieneAmbito, tituloMateria, portadaMateria, precioMateria, descripcionMateria, posicionGrado){
    this.id = idMateria;
    this.titleOfSubject2 = tituloMateria;
    this.imgOfSubject2 = portadaMateria;
    this.priceOfSubject2 = precioMateria;
    this.descriptionOfSubject2 = descripcionMateria;
    this.seccionSeleccionada2 = idSeccion;
    this.nombreSeccionSeleccionada = this.filtrarSecciones(this.seccionSeleccionada2)[0].nombre;
    this.grades2 = this.filtrarGradosPorSeccion(this.seccionSeleccionada2);
    this.gradoSeleccionado2 = idGrado;
    this.esAmbito2 = tieneAmbito;
    this.position = posicionGrado;
  }

  agregarMateria(){
    // Comprobar que no haya valores vacíos
    if(this.seccionSeleccionada !== '' && this.gradoSeleccionado !== '' && this.titleOfSubject !== '' && this.imgOfSubject !== '' 
    && this.priceOfSubject > 0 && this.descriptionOfSubject !== ''){
      let idRespuesta;
      this.api.agregarUnaMateria(this.token, this.seccionSeleccionada, this.gradoSeleccionado, this.esAmbito, this.titleOfSubject, this.imgOfSubject, this.priceOfSubject, this.descriptionOfSubject).subscribe(respuesta => {
        idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Materias');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otra materia. Prueba con otro diferente', 'Materias');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Materias');
        }
      }, () => {
        // Hacer push
        this.subjects.push({
          id: idRespuesta,
          seccion: this.seccionSeleccionada,
          nombreSeccion: this.nombreSeccionSeleccionada,
          grado: this.gradoSeleccionado,
          titulo: this.titleOfSubject.trim().toUpperCase(),
          portada: this.imgOfSubject.trim(),
          precio: Number(this.priceOfSubject),
          tiene_ambito: this.esAmbito,
          descripcion: this.descriptionOfSubject.trim(),
          utilizado: 0
        });
        // Notificar
        this.notify.showInfo('Se agregó la materia correctamente', 'Materias');
        // Restablecer valores
        this.esAmbito = true;
        this.seccionSeleccionada = '';
        this.gradoSeleccionado = '';
        this.grades2 = [];
        this.titleOfSubject = '';
        this.imgOfSubject = '';
        this.priceOfSubject = 100;
        this.descriptionOfSubject = '';
      });
    } else {
      // Mostrar alertas de campos requeridos
      if(this.seccionSeleccionada === ''){
        this.notify.showWarning('Selecciona un nivel académico', 'Materias');
      }
      if(this.gradoSeleccionado === ''){
        this.notify.showWarning('Selecciona un grado de estudio', 'Materias');
      }
      if(this.titleOfSubject === ''){
        this.notify.showWarning('Debes ingresar un título', 'Materias');
      }
      if(this.imgOfSubject === ''){
        this.notify.showWarning('Debes ingresar una url para la portada', 'Materias');
      }
      if(this.priceOfSubject === 0){
        this.notify.showWarning('Debes asignar un precio', 'Materias');
      }
      if(this.descriptionOfSubject === ''){
        this.notify.showWarning('Debes ingresar una descripción', 'Materias');
      }
    }
  }

  actualizarMateria(){
    // Comprobar que no haya valores vacíos
    if(this.titleOfSubject2 !== '' && this.imgOfSubject2 !== '' && this.priceOfSubject2 > 0 && this.descriptionOfSubject2 !== ''){
      let estadoRespuesta;
      this.api.actualizarUnaMateria(this.token, this.id, this.seccionSeleccionada2, this.gradoSeleccionado2, this.esAmbito2, this.titleOfSubject2, this.imgOfSubject2, this.priceOfSubject2, this.descriptionOfSubject2).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // console.log('Client-side error occured.');
        } else {
          // console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Materias');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otra materia. Prueba con otro diferente', 'Materias');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Materias');
        }
      }, () => {
        // Hacer push
        if(estadoRespuesta === 200){
          this.subjects[this.position].titulo = this.titleOfSubject2;
          this.subjects[this.position].portada = this.imgOfSubject2;
          this.subjects[this.position].precio = this.priceOfSubject2;
          this.subjects[this.position].descripcion = this.descriptionOfSubject2;
          // Notificar
          this.notify.showInfo('Se actualizó la materia correctamente', 'Materias');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Materias');
        }
      });
    } else {
      // Mostrar alertas de campos requeridos
      if(this.titleOfSubject === ''){
        this.notify.showWarning('Debes ingresar un título', 'Materias');
      }
      if(this.imgOfSubject === ''){
        this.notify.showWarning('Debes ingresar una url para la portada', 'Materias');
      }
      if(this.priceOfSubject === 0){
        this.notify.showWarning('Debes asignar un precio', 'Materias');
      }
      if(this.descriptionOfSubject === ''){
        this.notify.showWarning('Debes ingresar una descripción', 'Materias');
      }
    }
  }

  eliminarMateria(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnaMateria(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Grados');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('La materia que intentas eliminar no existe', 'Materias');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Materias');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.subjects.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el grado', 'Materias');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Materias');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'Materias');
    }
  }

  filtrarSecciones(key: string ) {
    return this.sections.filter(object => {
      return object.id === key;
    });
  }

  filtrarGradosPorSeccion(key: string ) {
    return this.grades.filter(object => {
      return object.seccion === key;
    });
  }

  seleccionarNivelAcademico(){
    if(this.seccionSeleccionada !== ''){
      this.grades2 = this.filtrarGradosPorSeccion(this.seccionSeleccionada);
    } else {
      this.grades2 = [];
    }
  }

  seleccionarAmbito(valorAmbito){
    if(valorAmbito){
      this.esAmbito = true;
    } else {
      this.esAmbito = false;
    }
  }

  seleccionarImagenDePortada(agregando){
    localStorage.removeItem('infoMateria');
    localStorage.removeItem('infoMateria2');
    localStorage.removeItem('infoMateria3');
    if(this.imgOfSubject === null){
      this.imgOfSubject = '';
    }
    if(this.seccionSeleccionada === null){
      this.seccionSeleccionada = '';
    }
    if(this.gradoSeleccionado === null){
      this.gradoSeleccionado = '';
    }
    if(this.titleOfSubject === null){
      this.titleOfSubject = '';
    }
    if(this.priceOfSubject === null){
      this.priceOfSubject = 0;
    }
    if(this.descriptionOfSubject === null){
      this.descriptionOfSubject = '';
    }
    if(agregando){
      this.infoMateria = {
        id: '',
        section: this.seccionSeleccionada,
        grade: this.gradoSeleccionado,
        esAmbito: this.esAmbito,
        titulo: this.titleOfSubject,
        portada: this.imgOfSubject,
        precio: this.priceOfSubject,
        descripcion: this.descriptionOfSubject
      };
      localStorage.setItem('infoMateria', JSON.stringify(this.infoMateria));
      this.hideAllModals();
      this.router.navigate(['', 'multimedia', 'images'], { queryParams: { agregandoMateria: 'true' } });
    } else {
      this.infoMateria = {
        id: this.id,
        section: this.seccionSeleccionada2,
        grade: this.gradoSeleccionado2,
        esAmbito: this.esAmbito2,
        titulo: this.titleOfSubject2,
        portada: this.imgOfSubject2,
        precio: this.priceOfSubject2,
        descripcion: this.descriptionOfSubject2
      };
      localStorage.setItem('infoMateria', JSON.stringify(this.infoMateria));
      this.hideAllModals();
      this.router.navigate(['', 'multimedia', 'images'], { queryParams: { agregandoMateria: 'false' } });
    }
  }

  openAddModal(){
    this.modal.open(this.addModal);
  }

  openEditModal(){
    this.modal.open(this.editModal);
  }

  hideAllModals(){
    this.modal.dismissAll();
  }

}
