import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-curricular-axes',
  templateUrl: './list-of-curricular-axes.component.html',
  styleUrls: ['./list-of-curricular-axes.component.css']
})
export class ListOfCurricularAxesComponent implements OnInit {

  public id = '';
  private id2 = '';
  private position = 0;
  public nameOfAxe = '';
  public nameOfAxe2 = '';
  private nameOfAxe3 = '';
  public axes = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_ejes;
      this.permisoEditar = info.permisos[0].actualizar_ejes;
      this.permisoEliminar = info.permisos[0].eliminar_ejes;
      if(info.permisos[0].visualizar_ejes === true){
        let listado;
        this.api.obtenerListadoEjes(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).ejes;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Ejes");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Ejes");
          }
        }, () => {
          this.axes = listado;
          this.notify.showInfo("Información actualizada", "Ejes");
        });
      } else if(info.permisos[0].agregar_ejes !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenNameOfAxe(name){
    name.target.value = name.target.value.toUpperCase();
    this.nameOfAxe = name.target.value;
    this.nameOfAxe2 = name.target.value;
  }

  listenIdOfAxe(id){
    this.id2 = id.target.value;
  }

  agregarEje(){
    if(this.nameOfAxe.trim() !== ''){
      let idRespuesta;
      this.api.agregarUnEje(this.token, this.nameOfAxe.trim().toUpperCase()).subscribe(respuesta => {
        idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', "Ejes");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro eje. Prueba con otro diferente', "Ejes");
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', "Ejes");
        }
      }, () => {
        this.notify.showInfo('Se agregó el eje correctamente', "Ejes");
        this.axes.push({
          id: idRespuesta,
          titulo: this.nameOfAxe.trim().toUpperCase(),
          utilizado: 0
        });
      });
    } else {
      this.notify.showWarning('No puedes agregar ámbitos sin nombre', "Ejes");
    }
  }

  actualizarEje(){
    if(this.nameOfAxe2.trim() !== this.nameOfAxe3.trim()){
      let estadoRespuesta;
      this.api.actualizarUnEje(this.token, this.id, this.nameOfAxe2.trim().toUpperCase()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', "Ejes");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro eje. Prueba con otro diferente', "Ejes");
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', "Ejes");
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.notify.showInfo('Se actualizó el eje', "Ejes");
          this.axes[this.position].titulo = this.nameOfAxe2.trim().toUpperCase();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', "Ejes");
        }
      });
    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', "Ejes");
    }
  }

  eliminarEje(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnEje(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', "Ejes");
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El eje que intentas eliminar no existe', "Ejes");
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', "Ejes");
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.axes.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el eje', "Ejes");
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', "Ejes");
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', "Ejes");
    }
  }

  mostrarEje(idEje, nombreEje, posicionEje){
    this.id = idEje;
    this.nameOfAxe2 = nombreEje;
    this.nameOfAxe3 = nombreEje;
    this.position = posicionEje;
  }

}
