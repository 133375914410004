import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { RestfulApiService } from '../../../services/restful/restful-api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {


  public toggleSidebar = false;
  constructor(private api: RestfulApiService) { }

  ngOnInit() {
  }

  ngDoCheck(){
    this.api.currentSidebar.subscribe(action => this.toggleSidebar = action);
  }

  verSidebarInMobile(){
    this.api.toggleSidebar(!this.toggleSidebar);
  }
  verSidebarInWeb(){
    const body = $('body');
    if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
      body.toggleClass('sidebar-hidden');
    } else {
      body.toggleClass('sidebar-icon-only');
    }
  }

}
