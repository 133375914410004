import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-ambits',
  templateUrl: './list-of-ambits.component.html',
  styleUrls: ['./list-of-ambits.component.css']
})
export class ListOfAmbitsComponent implements OnInit {

  public id = '';
  private id2 = '';
  private position = 0;
  public nameOfAmbit = '';
  public nameOfAmbit2 = '';
  private nameOfAmbit3 = '';
  public ambits = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_ambitos;
      this.permisoEditar = info.permisos[0].actualizar_ambitos;
      this.permisoEliminar = info.permisos[0].eliminar_ambitos;
      if(info.permisos[0].visualizar_ambitos === true){
        let listado;
        this.api.obtenerListadoAmbitos(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).ambitos;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Ámbitos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Ámbitos");
          }
        }, () => {
          this.ambits = listado;
          this.notify.showInfo("Información actualizada", "Ámbitos");
        });
      } else if(info.permisos[0].agregar_ambitos !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenNameOfAmbit(name){
    name.target.value = name.target.value.toUpperCase();
    this.nameOfAmbit = name.target.value;
    this.nameOfAmbit2 = name.target.value;
  }

  listenIdOfAmbit(id){
    this.id2 = id.target.value;
  }

  agregarAmbito(){
    if(this.nameOfAmbit.trim() !== ''){
      let idRespuesta;
      this.api.agregarUnAmbito(this.token, this.nameOfAmbit.trim().toUpperCase()).subscribe(respuesta => {
        idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Ámbitos');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro ámbito. Prueba con otro diferente', 'Ámbitos');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Ámbitos');
        }
      }, () => {
        this.notify.showInfo('Se agregó el ámbito correctamente', 'Ámbitos');
        this.ambits.push({
          id: idRespuesta,
          titulo: this.nameOfAmbit.trim().toUpperCase(),
          utilizado: 0
        });
      });
    } else {
      this.notify.showWarning('No puedes agregar ámbitos sin nombre', 'Ámbitos');
    }
  }

  actualizarAmbito(){
    if(this.nameOfAmbit2.trim() !== this.nameOfAmbit3.trim()){
      let estadoRespuesta;
      this.api.actualizarUnAmbito(this.token, this.id, this.nameOfAmbit2.trim().toUpperCase()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Ámbitos');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro ámbito. Prueba con otro diferente', 'Ámbitos');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Ámbitos');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.notify.showInfo('Se actualizó el ámbito', 'Ámbitos');
          this.ambits[this.position].titulo = this.nameOfAmbit2.trim().toUpperCase();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Ámbitos');
        }
      });
    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', 'Ámbitos');
    }
  }

  eliminarAmbito(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnAmbito(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Ámbitos');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El ámbito que intentas eliminar no existe', 'Ámbitos');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Ámbitos');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.ambits.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el ámbito', 'Ámbitos');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Ámbitos');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'Ámbitos');
    }
  }

  mostrarAmbito(idAmbito, nombreAmbito, posicionAmbito){
    this.id = idAmbito;
    this.nameOfAmbit2 = nombreAmbito;
    this.nameOfAmbit3 = nombreAmbito;
    this.position = posicionAmbito;
  }

}
