import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestfulApiService } from '../../../services/restful/restful-api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-specialist',
  templateUrl: './add-specialist.component.html',
  styleUrls: ['./add-specialist.component.css']
})
export class AddSpecialistComponent implements OnInit {

  private token = '';
  private permitirPeticion = true;
  public visualizarNiveles = false;
  public agregarNiveles = false;
  public editarNiveles = false;
  public eliminarNiveles = false;
  public visualizarGrados = false;
  public agregarGrados = false;
  public editarGrados = false;
  public eliminarGrados = false;
  public visualizarAsignaturas = false;
  public agregarAsignaturas = false;
  public editarAsignaturas = false;
  public eliminarAsignaturas = false;
  public visualizarWikis = false;
  public agregarWikis = false;
  public editarWikis = false;
  public eliminarWikis = false;
  public visualizarQuizzes = false;
  public visualizarBloques = false;
  public agregarBloques = false;
  public editarBloques = false;
  public eliminarBloques = false;
  public visualizarAmbitos = false;
  public agregarAmbitos = false;
  public editarAmbitos = false;
  public eliminarAmbitos = false;
  public visualizarPSL = false;
  public agregarPSL = false;
  public editarPSL = false;
  public eliminarPSL = false;
  public visualizarEje = false;
  public agregarEje = false;
  public editarEje = false;
  public eliminarEje = false;
  public visualizarTemas = false;
  public agregarTemas = false;
  public editarTemas = false;
  public eliminarTemas = false;
  public visualizarOrganizadores = false;
  public agregarOrganizador = false;
  public editarOrganizador = false;
  public eliminarOrganizador = false;
  public visualizarPaginas = false;
  public agregarPagina = false;
  public editarPagina = false;
  public eliminarPagina = false;
  public restaurarPagina = false;
  public eliminarPaginaDef = false;
  public visualizarImagenes = false;
  public agregarImagenes = false;
  public editarImagenes = false;
  public eliminarImagenes = false;
  public visualizarGalerias = false;
  public agregarGalerias = false;
  public editarGalerias = false;
  public eliminarGalerias = false;
  public visualizarVideos = false;
  public agregarVideos = false;
  public editarVideos = false;
  public eliminarVideos = false;
  public visualizarAudios = false;
  public agregarAudios = false;
  public editarAudios = false;
  public eliminarAudios = false;
  private nameOfSpecialist = '';

  constructor(private notify: ToastNotificationService,private api: RestfulApiService, private auth: AuthServiceService, private router: Router) { }

  ngOnInit() {
  }

  listenNameOfSpecialist(name){
    this.nameOfSpecialist = name.target.value.trim();
  }

  seleccionarPermiso(tipo, boolean){
    if(tipo === '1'){
      this.visualizarNiveles = boolean;
    } else if(tipo === '2'){
      this.agregarNiveles = boolean;
    } else if(tipo === '3'){
      this.editarNiveles = boolean;
    } else if(tipo === '4'){
      this.eliminarNiveles = boolean;
    } else if(tipo === '5'){
      this.visualizarGrados = boolean;
    } else if(tipo === '6'){
      this.agregarGrados = boolean;
    } else if(tipo === '7'){
      this.editarGrados = boolean;
    } else if(tipo === '8'){
      this.eliminarGrados = boolean;
    } else if(tipo === '9'){
      this.visualizarAsignaturas = boolean;
    } else if(tipo === '10'){
      this.agregarAsignaturas = boolean;
    } else if(tipo === '11'){
      this.editarAsignaturas = boolean;
    } else if(tipo === '12'){
      this.eliminarAsignaturas = boolean;
    } else if(tipo === '13'){
      this.visualizarWikis = boolean;
    } else if(tipo === '14'){
      this.agregarWikis = boolean;
    } else if(tipo === '15'){
      this.editarWikis = boolean;
    } else if(tipo === '16'){
      this.eliminarWikis = boolean;
    } else if(tipo === '17'){
      this.visualizarQuizzes = boolean;
    } else if(tipo === '18'){
      this.visualizarBloques = boolean;
    } else if(tipo === '19'){
      this.agregarBloques = boolean;
    } else if(tipo === '20'){
      this.editarBloques = boolean;
    } else if(tipo === '21'){
      this.eliminarBloques = boolean;
    } else if(tipo === '22'){
      this.visualizarAmbitos = boolean;
    } else if(tipo === '23'){
      this.agregarAmbitos = boolean;
    } else if(tipo === '24'){
      this.editarAmbitos = boolean;
    } else if(tipo === '25'){
      this.eliminarAmbitos = boolean;
    } else if(tipo === '26'){
      this.visualizarPSL = boolean;
    } else if(tipo === '27'){
      this.agregarPSL = boolean;
    } else if(tipo === '28'){
      this.editarPSL = boolean;
    } else if(tipo === '29'){
      this.eliminarPSL = boolean;
    } else if(tipo === '30'){
      this.visualizarEje = boolean;
    } else if(tipo === '31'){
      this.agregarEje = boolean;
    } else if(tipo === '32'){
      this.editarEje = boolean;
    } else if(tipo === '33'){
      this.eliminarEje = boolean;
    } else if(tipo === '34'){
      this.visualizarTemas = boolean;
    } else if(tipo === '35'){
      this.agregarTemas = boolean;
    } else if(tipo === '36'){
      this.editarTemas = boolean;
    } else if(tipo === '37'){
      this.eliminarTemas = boolean;
    } else if(tipo === '38'){
      this.visualizarOrganizadores = boolean;
    } else if(tipo === '39'){
      this.agregarOrganizador = boolean;
    } else if(tipo === '40'){
      this.editarOrganizador = boolean;
    } else if(tipo === '41'){
      this.eliminarOrganizador = boolean;
    } else if(tipo === '42'){
      this.visualizarPaginas = boolean;
    } else if(tipo === '43'){
      this.agregarPagina = boolean;
    } else if(tipo === '44'){
      this.editarPagina = boolean;
    } else if(tipo === '45'){
      this.eliminarPagina = boolean;
    } else if(tipo === '46'){
      this.restaurarPagina = boolean;
    } else if(tipo === '47'){
      this.eliminarPaginaDef = boolean;
    } else if(tipo === '48'){
      this.visualizarImagenes = boolean;
    } else if(tipo === '49'){
      this.agregarImagenes = boolean;
    } else if(tipo === '50'){
      this.editarImagenes = boolean;
    } else if(tipo === '51'){
      this.eliminarImagenes = boolean;
    } else if(tipo === '52'){
      this.visualizarGalerias = boolean;
    } else if(tipo === '53'){
      this.agregarGalerias = boolean;
    } else if(tipo === '54'){
      this.editarGalerias = boolean;
    } else if(tipo === '55'){
      this.eliminarGalerias = boolean;
    } else if(tipo === '56'){
      this.visualizarVideos = boolean;
    } else if(tipo === '57'){
      this.agregarVideos = boolean;
    } else if(tipo === '58'){
      this.editarVideos = boolean;
    } else if(tipo === '59'){
      this.eliminarVideos = boolean;
    } else if(tipo === '60'){
      this.visualizarAudios = boolean;
    } else if(tipo === '61'){
      this.agregarAudios = boolean;
    } else if(tipo === '62'){
      this.eliminarAudios = boolean;
    } else if(tipo === '63'){
      this.editarAudios = boolean;
    }
  }

  agregarEspecialista(){
    if(this.nameOfSpecialist.trim() !== ''){
      const permisos = [{
        visualizar_niveles_académicos: this.visualizarNiveles,
        agregar_niveles_académicos: this.agregarNiveles,
        actualizar_niveles_académicos: this.editarNiveles,
        eliminar_niveles_académicos: this.eliminarNiveles,
        visualizar_grados_de_estudio: this.visualizarGrados,
        agregar_grados_de_estudio: this.agregarGrados,
        actualizar_grados_de_estudio: this.editarGrados,
        eliminar_grados_de_estudio: this.eliminarGrados,
        visualizar_materias: this.visualizarAsignaturas,
        agregar_materias: this.agregarAsignaturas,
        actualizar_materias: this.editarAsignaturas,
        eliminar_materias: this.eliminarAsignaturas,
        visualizar_wikis: this.visualizarWikis,
        agregar_wikis: this.agregarWikis,
        actualizar_wikis: this.editarWikis,
        eliminar_wikis: this.eliminarWikis,
        visualizar_quizzes: this.visualizarQuizzes,
        visualizar_bloques: this.visualizarBloques,
        agregar_bloques: this.agregarBloques,
        actualizar_bloques: this.editarBloques,
        eliminar_bloques: this.eliminarBloques,
        visualizar_secuencias: this.visualizarOrganizadores,
        agregar_secuencias: this.agregarOrganizador,
        actualizar_secuencias: this.editarOrganizador,
        eliminar_secuencias: this.eliminarOrganizador,
        visualizar_ambitos: this.visualizarAmbitos,
        agregar_ambitos: this.agregarAmbitos,
        actualizar_ambitos: this.editarAmbitos,
        eliminar_ambitos: this.eliminarAmbitos,
        visualizar_psls: this.visualizarPSL,
        agregar_psls: this.agregarPSL,
        actualizar_psls: this.editarPSL,
        eliminar_psls: this.eliminarPSL,
        visualizar_ejes: this.visualizarEje,
        agregar_ejes: this.agregarEje,
        actualizar_ejes: this.editarEje,
        eliminar_ejes: this.eliminarEje,
        visualizar_temas: this.visualizarTemas,
        agregar_temas: this.agregarTemas,
        actualizar_temas: this.editarTemas,
        eliminar_temas: this.eliminarTemas,
        visualizar_paginas: this.visualizarPaginas,
        agregar_paginas: this.agregarPagina,
        actualizar_paginas: this.editarPagina,
        eliminar_paginas: this.eliminarPagina,
        restaurar_paginas: this.restaurarPagina,
        eliminar_paginas_definitivamente: this.eliminarPaginaDef,
        visualizar_imagenes: this.visualizarImagenes,
        agregar_imagenes: this.agregarImagenes,
        actualizar_imagenes: this.editarImagenes,
        eliminar_imagenes: this.eliminarImagenes,
        visualizar_galerias: this.visualizarGalerias,
        agregar_galerias: this.agregarGalerias,
        actualizar_galerias: this.editarGalerias,
        eliminar_galerias: this.eliminarGalerias,
        visualizar_videos: this.visualizarVideos,
        agregar_videos: this.agregarVideos,
        actualizar_videos: this.editarVideos,
        eliminar_videos: this.eliminarVideos,
        visualizar_audios: this.visualizarAudios,
        agregar_audios: this.agregarAudios,
        eliminar_audios: this.eliminarAudios,
        actualizar_audios: this.editarAudios
      }];
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        let estadoRes;
        if(this.permitirPeticion){
          this.permitirPeticion = false;
          this.api.agregarUnEspecialista(this.token, this.nameOfSpecialist.trim(), permisos).subscribe(respuesta => {
            estadoRes = JSON.parse(JSON.stringify(respuesta)).estado;
          }, (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              //console.log('Client-side error occured.');
            } else {
              //console.log('Server-side error occured.');
            }
            if (err.status === 400) {
              this.notify.showError("Error 400", "Especialistas");
            }
            if (err.status === 401 || err.status === 404) {
              this.auth.eliminarToken();
              this.router.navigate(['', 'auth']);
            }
            if (err.status === 500) {
              this.notify.showError("Error 500", "Especialistas");
            }
          }, () => {
            if(estadoRes === 201){
              this.permitirPeticion = true;
              this.router.navigate(['', 'admin', 'specialists']);
            } else {
              this.notify.showError("Ocurrió un error inesperado", "Especialistas");
            }
          });
        } else {
          this.notify.showWarning('Por favor espere...', 'Especialistas');
        }
      } else {
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.notify.showWarning('El nombre es requerido', 'Especialistas');
    }
  }

}
