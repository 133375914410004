import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestfulApiService } from '../../../services/restful/restful-api.service';


@Component({
  selector: 'app-list-of-specialists',
  templateUrl: './list-of-specialists.component.html',
  styleUrls: ['./list-of-specialists.component.css']
})
export class ListOfSpecialistsComponent implements OnInit {

  public showInfoEspecialista = false;
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoRestablecerPass = false;
  public permisoEliminar = false;
  public specialists = [];
  private token = '';
  public id = '';
  public passEspecialista = 'Cargando...';
  private id2 = '';
  public id3 = '';
  private position = 0;
  public nameOfSpecialist = '';
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_especialista;
      this.permisoEditar = info.permisos[0].actualizar_permisos_especialista;
      this.permisoRestablecerPass = info.permisos[0].actualizar_pass_especialista;
      this.permisoEliminar = info.permisos[0].eliminar_especialista;
      if(info.permisos[0].visualizar_especialistas === true){
        let listado;
        this.api.obtenerListadoEspecialistas(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).especialistas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Especialistas");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Especialistas");
          }
        }, () => {
          this.specialists = listado;
          // console.log("ESPECIALISTAS: ", this.specialists);
          this.specialists.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
          this.notify.showInfo("Información actualizada", "Especialistas");
        });
      } else if(info.permisos[0].agregar_especialista !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
      // RESET DE LEVELS
      // for(let i = 0; i < this.levels.length; i++){
      //   this.levels[i].selected = false;
      // }
      // this.showInfoEscuela = false;
      // this.showInfoEscuela2 = false;
      // this.claveEscuela = 'Cargando...';
      // this.passEscuela = 'Cargando...';
      // this.passEscuela2 = 'Cargando...';
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      //console.log(`Dismissed: ${reason}`);
      // RESET DE LEVELS
      // for(let i = 0; i < this.levels.length; i++){
      //   this.levels[i].selected = false;
      // }
      // this.showInfoEscuela = false;
      // this.showInfoEscuela2 = false;
      // this.claveEscuela = 'Cargando...';
      // this.passEscuela = 'Cargando...';
      // this.passEscuela2 = 'Cargando...';
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenIdOfSpecialist(id){
    this.id3 = id.target.value.trim();
  }

  mostrarEspecialista(idSpecialist, nameOfSpecialist,posicionSpecialist){
    this.id = idSpecialist;
    this.id2 = idSpecialist;
    this.nameOfSpecialist = nameOfSpecialist;
    this.position = posicionSpecialist;
  }

  editSpecialist(){
    // console.log("this.specialists[this.position]: ", this.specialists[this.position]);
    sessionStorage.setItem('RXNwZWNpYWxpc3Rh', JSON.stringify(this.specialists[this.position]));
    this.router.navigate(['', 'admin', 'specialists', 'edit']);
  }

  hideAllModals(){
    this.modal.dismissAll();
    this.showInfoEspecialista = false;
    this.passEspecialista = 'Cargando...';
  }

  renovarPassEspecialista(){
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    if(!tokenEspirado && this.token !== null){
      this.showInfoEspecialista = true;
      this.notify.showInfo('Procesando solicitud...', 'Especialistas');
      let passEsp;
      this.api.renovarPassEspecialista(this.token, this.id2).subscribe(respuesta => {
        passEsp = JSON.parse(JSON.stringify(respuesta)).pass;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Especialistas');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El especialista que intentas actualizar no existe', 'Especialistas');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Especialistas');
        }
      }, () => {
        this.passEspecialista = passEsp;
        this.notify.showInfo('Se renovó la contraseña correctamente', 'Especialistas');
      });
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  eliminarEspecialista(){
    if(this.id2.trim() === this.id3.trim()){
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        let codigoRes;
        this.api.eliminarUnEspecialista(this.token, this.id2).subscribe(respuesta => {
          codigoRes = JSON.parse(JSON.stringify(respuesta)).estado;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Especialistas');
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 403) {
            this.notify.showWarning('El especialista que intentas actualizar no existe', 'Especialistas');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Especialistas');
          }
        }, () => {
          if(codigoRes === 200){
            this.specialists.splice(this.position, 1);
            this.hideAllModals();
            this.notify.showInfo('Se eliminó el especialista correctamente', 'Especialistas');
          } else {
            this.notify.showWarning('Ocurrió un error inesperado', 'Especialistas');
          }
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.notify.showWarning('El ID es incorrecto', 'Especialistas');
    }
  }

}
