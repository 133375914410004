import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-pages-of-a-topic',
  templateUrl: './list-of-pages-of-a-topic.component.html',
  styleUrls: ['./list-of-pages-of-a-topic.component.css']
})
export class ListOfPagesOfATopicComponent implements OnInit {

  public sections = [];
  public grades = [];
  public grades2 = [];
  public subjects = [];
  public subjects2 = [];
  public blocks = [];
  public blocks2 = [];
  public sequences = [];
  public sequences2 = [];
  public pages = [];
  public pages2 = [];
  public topics = [];
  public psl = [];
  public seccionSeleccionada = '';
  public gradoSeleccionado = '';
  public materiaSeleccionada = '';
  public bloqueSeleccionada = '';
  public secuenciaSeleccionada = '';
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  public permisoRestaurar = false;
  public permisoEliminarDenifitivamente = false;
  private token = '';
  public id = '';
  public id2 = '';
  private posicionPaginaSeleccionada;
  public puedeUtilizarBoton = false;
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    localStorage.removeItem('detallePagina');
    sessionStorage.removeItem('secciones');
    sessionStorage.removeItem('grados');
    sessionStorage.removeItem('materias');
    sessionStorage.removeItem('bloques');
    sessionStorage.removeItem('secuencias');
    sessionStorage.removeItem('paginas');
    sessionStorage.removeItem('ambitos');
    sessionStorage.removeItem('psl');
    sessionStorage.removeItem('ejes');
    sessionStorage.removeItem('temas');
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_paginas;
      this.permisoEditar = info.permisos[0].actualizar_paginas;
      this.permisoEliminar = info.permisos[0].eliminar_paginas;
      this.permisoRestaurar = info.permisos[0].restaurar_paginas;
      this.permisoEliminarDenifitivamente = info.permisos[0].eliminar_paginas_definitivamente;
      if(info.permisos[0].visualizar_paginas === true){
        let listado;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.sections = listado;
        });
        let listado2;
        this.api.obtenerListadoGrados(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).grados;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Grados");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Grados");
          }
        }, () => {
          this.grades = listado2;
        });
        let listado3;
        this.api.obtenerListadoMaterias(this.token).subscribe(respuesta => {
          listado3 = JSON.parse(JSON.stringify(respuesta)).materias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Materias");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Materias");
          }
        }, () => {
          this.subjects = listado3;
        });
        let listado4;
        this.api.obtenerListadoBloques(this.token).subscribe(respuesta => {
          listado4 = JSON.parse(JSON.stringify(respuesta)).bloques;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Bloques");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Bloques");
          }
        }, () => {
          this.blocks = listado4;
        });
        let listado5;
        this.api.obtenerListadoSecuencias(this.token).subscribe(respuesta => {
          listado5 = JSON.parse(JSON.stringify(respuesta)).secuencias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Bloques");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Bloques");
          }
        }, () => {
          this.sequences = listado5;
        });
        let listado6;
        this.api.obtenerListadoPaginas(this.token).subscribe(respuesta => {
          listado6 = JSON.parse(JSON.stringify(respuesta)).paginas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Páginas");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Páginas");
          }
        }, () => {
          this.puedeUtilizarBoton = true;
          this.pages = listado6;
          this.pages2 = listado6;
          this.notify.showInfo("Información actualizada", "Páginas");
        });
      } else if(info.permisos[0].agregar_paginas !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  ngDoCheck(){
    for(let m = 0; m < this.pages.length; m++){
      for(let k = 0; k < this.sequences.length; k++){
        for(let l = 0; l < this.subjects.length; l++){
            if(this.pages[m].secuencia === this.sequences[k].id){
              this.pages[m].nombreSecuencia = this.sequences[k].titulo;
              this.pages[m].sesion = this.sequences[k].sesion;
            }
            if(this.pages[m].materia === this.subjects[l].id){
              this.pages[m].nombreMateria = this.subjects[l].titulo;
            }
        }
      }
    }
    this.pages.sort(function(a, b){
      if(a.pagina < b.pagina) { return -1; }
    });
  }

  seleccionarNivelAcademico(){
    if(this.seccionSeleccionada !== ''){
      // console.log("this.seccionSeleccionada: ", this.seccionSeleccionada);
      this.grades2 = this.filtrarGradosPorSeccion(this.seccionSeleccionada);
      this.pages2 = this.filtrarPaginasPorNivel(this.pages, this.seccionSeleccionada);
      // console.log("pages2 length: ", this.pages2.length);
      // console.log("pages2: ", this.pages2);
    } else {
      this.grades2 = [];
      this.pages2 = this.pages;
    }
    this.subjects2 = [];
    this.blocks2 = [];
    this.sequences2 = [];
    this.gradoSeleccionado = '';
    this.materiaSeleccionada = '';
    this.bloqueSeleccionada = '';
    this.secuenciaSeleccionada = '';
  }

  seleccionarGrado(){
    if(this.gradoSeleccionado !== ''){
      this.subjects2 = this.filtrarMateriasPorGrado(this.gradoSeleccionado);
      this.pages2 = this.filtrarPaginasPorGrado(this.pages, this.seccionSeleccionada, this.gradoSeleccionado);
    } else {
      this.subjects2 = [];
      this.pages2 = this.filtrarPaginasPorNivel(this.pages, this.seccionSeleccionada);
    }
    // this.pages2 = [];
    this.blocks2 = [];
    this.sequences2 = [];
    this.materiaSeleccionada = '';
    this.bloqueSeleccionada = '';
    this.secuenciaSeleccionada = '';
  }

  seleccionarMateria(){
    if(this.materiaSeleccionada !== ''){
      this.blocks2 = this.filtrarBloquesPorMateria(this.materiaSeleccionada);
      this.pages2 = this.filtrarPaginasPorMateria(this.pages, this.seccionSeleccionada, this.gradoSeleccionado, this.materiaSeleccionada);
    } else {
      this.blocks2 = [];
      this.pages2 = this.filtrarPaginasPorGrado(this.pages, this.seccionSeleccionada, this.gradoSeleccionado);
    }
    // this.pages2 = [];
    this.sequences2 = [];
    this.bloqueSeleccionada = '';
    this.secuenciaSeleccionada = '';
  }

  seleccionarBloque(){
    if(this.bloqueSeleccionada !== ''){
      this.sequences2 = this.filtrarSecuenciasPorBloque(this.bloqueSeleccionada);
      this.pages2 = this.filtrarPaginasPorBloque(this.pages, this.seccionSeleccionada, this.gradoSeleccionado, this.materiaSeleccionada, this.bloqueSeleccionada);
    } else {
      this.sequences2 = [];
      this.pages2 = this.filtrarPaginasPorMateria(this.pages, this.seccionSeleccionada, this.gradoSeleccionado, this.materiaSeleccionada);
    }
    // this.pages2 = [];
    this.secuenciaSeleccionada = '';
  }

  seleccionarSecuencia(){
    if(this.secuenciaSeleccionada !== ''){
      // this.pages2 = this.filtrarPaginasPorSecuencia(this.secuenciaSeleccionada);
      this.pages2 = this.filtrarPaginasPorSecuencia(this.pages, this.seccionSeleccionada, this.gradoSeleccionado, this.materiaSeleccionada, this.bloqueSeleccionada, this.secuenciaSeleccionada);
    } else {
      // this.pages2 = [];
      this.pages2 = this.filtrarPaginasPorBloque(this.pages, this.seccionSeleccionada, this.gradoSeleccionado, this.materiaSeleccionada, this.bloqueSeleccionada);
    }
  }

  

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenIdOfPage(name){
    this.id2 = name.target.value;
  }

  infoModal(position){
    this.id = this.pages2[position].id;
    this.posicionPaginaSeleccionada = position;
  }

  filtrarGradosPorSeccion(key: string ) {
    return this.grades.filter(object => {
      return object.seccion === key;
    });
  }

  filtrarMateriasPorGrado(key: string ) {
    return this.subjects.filter(object => {
      return object.grado === key;
    });
  }

  filtrarBloquesPorMateria(key: string ) {
    return this.blocks.filter(object => {
      return object.materia === key;
    });
  }

  filtrarSecuenciasPorBloque(key: string ) {
    return this.sequences.filter(object => {
      return object.bloque === key;
    });
  }

  // filtrarPaginasPorSecuencia(key: string ) {
  //   return this.pages.filter(object => {
  //     return object.secuencia === key;
  //   });
  // }

  filtrarPaginasPorNivel(listado, key: string ) {
    return listado.filter(object => {
      return object.seccion === key;
    });
  }

  filtrarPaginasPorGrado(listado, key: string, key2: string ) {
    return listado.filter(object => {
      return object.seccion === key && object.grado === key2;
    });
  }

  filtrarPaginasPorMateria(listado, key: string, key2: string, key3: string ) {
    return listado.filter(object => {
      return object.seccion === key && object.grado === key2 && object.materia === key3;
    });
  }

  filtrarPaginasPorBloque(listado, key: string, key2: string, key3: string, key4: string ) {
    return listado.filter(object => {
      return object.seccion === key && object.grado === key2 && object.materia === key3 && object.bloque === key4;
    });
  }

  filtrarPaginasPorSecuencia(listado, key: string, key2: string, key3: string, key4: string, key5: string ) {
    return listado.filter(object => {
      return object.seccion === key && object.grado === key2 && object.materia === key3 && object.bloque === key4 && object.secuencia === key5;
    });
  }

  actualizarNumeroDePagina(id, numero){
    for(let i = 0; i < this.pages.length; i++){
      if(id === this.pages[i].clave){
        this.pages[i].pagina = numero;
      }
    }
  }


  editarPagina(posicion){
    localStorage.setItem('detallePagina', JSON.stringify(this.pages2[posicion]));
    sessionStorage.setItem('secciones', JSON.stringify(this.sections));
    sessionStorage.setItem('grados', JSON.stringify(this.grades));
    sessionStorage.setItem('materias', JSON.stringify(this.subjects));
    sessionStorage.setItem('bloques', JSON.stringify(this.blocks));
    sessionStorage.setItem('secuencias', JSON.stringify(this.sequences));
    sessionStorage.setItem('paginas', JSON.stringify(this.pages));
    this.router.navigate(['', 'books', 'pages', 'edit']);
  }

  agregarUnaPagina(){
    sessionStorage.setItem('secciones', JSON.stringify(this.sections));
    sessionStorage.setItem('grados', JSON.stringify(this.grades));
    sessionStorage.setItem('materias', JSON.stringify(this.subjects));
    sessionStorage.setItem('bloques', JSON.stringify(this.blocks));
    sessionStorage.setItem('secuencias', JSON.stringify(this.sequences));
    sessionStorage.setItem('paginas', JSON.stringify(this.pages));
    this.router.navigate(['', 'books', 'pages', 'add']);
  }

  eliminarPagina(){
    if(this.id.trim() === this.id2.trim()){
      let estado;
      this.api.eliminarUnaPagina(this.token, this.id).subscribe(respuesta => {
        estado = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError("Error 400", "Paginas");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 500) {
          this.notify.showError("Error 500", "Paginas");
        }
      }, () => {
        if(estado === 200){
          for(let k = 0; k < this.pages2.length; k++){
            if (this.pages2[k].id !== this.pages2[this.posicionPaginaSeleccionada].id) {
              if (this.pages2[k].pagina > this.pages2[this.posicionPaginaSeleccionada].pagina) {
                this.pages2[k].pagina = this.pages2[k].pagina - 1;
                this.actualizarNumeroDePagina(this.pages2[k].id, this.pages2[k].pagina - 1);
              }
            } else {
              this.pages2[this.posicionPaginaSeleccionada].estado = 'Eliminada';
            }
          }
          for(let i = 0; i < this.pages.length; i++){
            if(this.id.trim() === this.pages[i].id){
              this.pages[i].estado = 'Eliminada';
            }
          }
          this.notify.showInfo('Pagina eliminada correctamente', 'Paginas')
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Paginas');
        }
      });
    } else {
      this.notify.showWarning('Verifica que los ID coincidan', 'Pagina');
    }
  }

  restaurarPagina(){
    if(this.id.trim() === this.id2.trim()){
      let estado;
      this.api.restaurarUnaPagina(this.token, this.id).subscribe(respuesta => {
        estado = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError("Error 400", "Paginas");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 500) {
          this.notify.showError("Error 500", "Paginas");
        }
      }, () => {
        if(estado === 200){
          for(let k = 0; k < this.pages2.length; k++){
            if (this.pages2[k].id !== this.pages2[this.posicionPaginaSeleccionada].id) {
              if (this.pages2[k].pagina >= this.pages2[this.posicionPaginaSeleccionada].pagina) {
                this.pages2[k].pagina = this.pages2[k].pagina + 1;
                this.actualizarNumeroDePagina(this.pages2[k].id, this.pages2[k].pagina + 1);
              }
            } else {
              this.pages2[this.posicionPaginaSeleccionada].estado = 'Borrador';
            }
          }
          for(let i = 0; i < this.pages.length; i++){
            if(this.id.trim() === this.pages[i].id){
              this.pages[i].estado = 'Borrador';
            }
          }
          this.notify.showInfo('Pagina restaurada correctamente', 'Paginas')
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Paginas');
        }
      });
    } else {
      this.notify.showWarning('Verifica que los ID coincidan', 'Pagina');
    }
  }

  eliminarPaginaDefinitivamente(){
    if(this.id.trim() === this.id2.trim()){
      let estado;
      this.api.eliminarUnaPaginaDefintiivamente(this.token, this.id).subscribe(respuesta => {
        estado = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError("Error 400", "Paginas");
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 500) {
          this.notify.showError("Error 500", "Paginas");
        }
      }, () => {
        if(estado === 200){
          this.pages2.splice(this.posicionPaginaSeleccionada, 1);
          for(let i = 0; i < this.pages.length; i++){
            if(this.id.trim() === this.pages[i].id){
              this.pages.splice(i, 1);
            }
          }
          this.notify.showInfo('Pagina eliminada definitivamente', 'Paginas')
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Paginas');
        }
      });
    } else {
      this.notify.showWarning('Verifica que los ID coincidan', 'Pagina');
    }
  }

}
