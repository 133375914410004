export const environment = {
  production: true,
  // url_principal: 'https://sandbox.gateway.ebe.jenios.mx/v1.0.0',
  //url_principal: 'https://gateway.ebe.jenios.mx/v1.0.0',
  //url_principal: 'http://localhost:8443/v1.0.0',
  url_principal: 'https://api.ebe-smart.app/v1.0.0',
  url_jenios: 'https://api.jenios.mx/jenios/v1.0.0',
  public: 'lXMa2E8j5E2KnZ47F24sGgc36B7OJ9Fe8Ft79t3vdQQ9xZ6eizWFo8WwRD2T51xMROUxm4aUoHb7bbgYxJ7DgCpHun8pJyrnTdL1GsXgYO96VG6X8YhsVqRaMWpUHbZt',
  publicJenios: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiRkNrVTFvVXhBd0gyQ0NXOUszR2ZMWWdFQlZYSjBnYTMiLCJpYXQiOjE2MDY1ODIxNjIsImF1ZCI6Imh0dHBzOi8vZWJlLmplbmlvcy5teCJ9.FdzBf6ZGyH6ViwFTXpP2hzHzawhPmyGtRjTWmpuG_Ng',
  publicKeyJenios: 'FCkU1oUxAwH2CCW9K3GfLYgEBVXJ0ga3',
  //url_bucket: 'https://f1.s1.jenios.mx',
  url_bucket: 'https://bucket.ebe-smart.app',
  //url_bucket: 'http://localhost:3000',
  url_quizzes: 'https://quiz-ebe.ebe-smart.app'
};
