import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestfulApiService {
  private headers: HttpHeaders;
  private headerAuth: HttpHeaders;
  private headerJenios: HttpHeaders;
  private sidebarSource = new BehaviorSubject(false);
  currentSidebar = this.sidebarSource.asObservable();

  constructor(private http: HttpClient) {
    /* HEADER PUBLICO*/
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `EBE ${environment.public}`
    });
    this.headerJenios = new HttpHeaders({
      Authorization: `Jenios ${environment.publicJenios}`
    });
  }

  funcionHeader(token) {
    /* HEADER AUTH*/
    return this.headerAuth = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'EBE ' + token
    });
  }

  // SIDEBAR
  toggleSidebar(action: boolean) {
    this.sidebarSource.next(action)
  }

  /* AUTH */
  autenticarmeComoAdmin(clave, secreto) {
    const url = `${environment.url_principal}/operacion/auth/admin`;
    return this.http
      .post(url, { usuario: clave, secret: secreto, tipo: 'Administrador' }, { headers: this.headers })
      .pipe(map(data => data));
  }

  // SECCIONES
  obtenerListadoSecciones(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/secciones`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaSeccion(token, nombreSeccion) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/secciones`;

    return this.http
      .post(url, { nombre: nombreSeccion }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaSeccion(token, idSeccion, nombreSeccion) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/secciones`;

    return this.http
      .put(url, { id: idSeccion, nombre: nombreSeccion }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaSeccion(token, idSeccion) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/secciones/eliminar`;

    return this.http
      .post(url, { id: idSeccion }, { headers: acceso })
      .pipe(map(data => data));
  }

  // GRADOS
  obtenerListadoGrados(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/grados`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnGrado(token, seccionGrado, nombreGrado) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/grados`;

    return this.http
      .post(url, { seccion: seccionGrado, nombre: nombreGrado }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnGrado(token, idGrado, nombreGrado) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/grados`;

    return this.http
      .put(url, { id: idGrado, nombre: nombreGrado }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnGrado(token, idGrado) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/grados/eliminar`;

    return this.http
      .post(url, { id: idGrado }, { headers: acceso })
      .pipe(map(data => data));
  }

  // MATERIAS
  obtenerListadoMaterias(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/materias`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaMateria(token, seccionMateria, gradoMateria, ambitoMateria, tituloMateria, portadaMateria, precioMateria, descripcionMateria) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/materias`;

    return this.http
      .post(url, { seccion: seccionMateria, grado: gradoMateria, ambito: ambitoMateria, titulo: tituloMateria, url_portada: portadaMateria, precio: precioMateria, descripcion: descripcionMateria }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaMateria(token, idMateria, seccionMateria, gradoMateria, ambitoMateria, tituloMateria, portadaMateria, precioMateria, descripcionMateria) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/materias`;

    return this.http
      .put(url, { id: idMateria, seccion: seccionMateria, grado: gradoMateria, ambito: ambitoMateria, titulo: tituloMateria, url_portada: portadaMateria, precio: precioMateria, descripcion: descripcionMateria }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaMateria(token, idMateria) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/materias/eliminar`;

    return this.http
      .post(url, { id: idMateria }, { headers: acceso })
      .pipe(map(data => data));
  }

  // BLOQUES
  obtenerListadoBloques(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/bloques`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnBloque(token, seccionBloque, gradoBloque, materiaBloque, tituloBloque) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/bloques`;

    return this.http
      .post(url, { seccion: seccionBloque, grado: gradoBloque, materia: materiaBloque, titulo: tituloBloque }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnBloque(token, idBloque, tituloBloque) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/bloques`;

    return this.http
      .put(url, { id: idBloque, titulo: tituloBloque }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnBloque(token, idBloque) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/bloques/eliminar`;

    return this.http
      .post(url, { id: idBloque }, { headers: acceso })
      .pipe(map(data => data));
  }

  // SECUENCIAS
  obtenerListadoSecuencias(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/secuencias`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaSecuencia(token, seccionSecuencia, gradoSecuencia, materiaSecuencia, bloqueSecuencia, tituloSecuencia) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/secuencias`;

    return this.http
      .post(url, { seccion: seccionSecuencia, grado: gradoSecuencia, materia: materiaSecuencia, bloque: bloqueSecuencia, titulo: tituloSecuencia }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaSecuencia(token, idSecuencia, tituloSecuencia) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/secuencias`;

    return this.http
      .put(url, { id: idSecuencia, titulo: tituloSecuencia }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaSecuencia(token, idSecuencia) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/secuencias/eliminar`;

    return this.http
      .post(url, { id: idSecuencia }, { headers: acceso })
      .pipe(map(data => data));
  }

  // ÁMBITOS
  obtenerListadoAmbitos(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ambitos`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnAmbito(token, tituloAmbito) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ambitos`;

    return this.http
      .post(url, { titulo: tituloAmbito }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnAmbito(token, idAmbito, tituloAmbito) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ambitos`;

    return this.http
      .put(url, { id: idAmbito, titulo: tituloAmbito }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnAmbito(token, idAmbito) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ambitos/eliminar`;

    return this.http
      .post(url, { id: idAmbito }, { headers: acceso })
      .pipe(map(data => data));
  }

  // PSL
  obtenerListadoPSL(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/psl`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaPSL(token, tituloPSL, aprendizajePSL, materiaPSL) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/psl`;

    return this.http
      .post(url, { titulo: tituloPSL, aprendizaje: aprendizajePSL, materia: materiaPSL }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaPSL(token, idPSL, tituloPSL, aprendizajePSL) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/psl`;

    return this.http
      .put(url, { id: idPSL, titulo: tituloPSL, aprendizaje: aprendizajePSL }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaPSL(token, idPSL) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/psl/eliminar`;

    return this.http
      .post(url, { id: idPSL }, { headers: acceso })
      .pipe(map(data => data));
  }

  // EJES
  obtenerListadoEjes(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ejes`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnEje(token, tituloEje) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ejes`;

    return this.http
      .post(url, { titulo: tituloEje }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnEje(token, idEje, tituloEje) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ejes`;

    return this.http
      .put(url, { id: idEje, titulo: tituloEje }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnEje(token, idEje) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/ejes/eliminar`;

    return this.http
      .post(url, { id: idEje }, { headers: acceso })
      .pipe(map(data => data));
  }

  // TEMAS
  obtenerListadoTemas(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/temas`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnTema(token, tituloTema, aprendizajeTema, materiaTema) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/temas`;

    return this.http
      .post(url, { titulo: tituloTema, aprendizaje: aprendizajeTema, materia: materiaTema }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnTema(token, idTema, tituloTema, aprendizajeTema) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/temas`;

    return this.http
      .put(url, { id: idTema, titulo: tituloTema, aprendizaje: aprendizajeTema }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnTema(token, idTema) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/temas/eliminar`;

    return this.http
      .post(url, { id: idTema }, { headers: acceso })
      .pipe(map(data => data));
  }

  // WIKIS
  obtenerListadoWikis(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/wikis`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaWiki(token, tituloWiki, descripcionWiki) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/wikis`;

    return this.http
      .post(url, { titulo: tituloWiki, descripcion: descripcionWiki }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaWiki(token, idWiki, tituloWiki, descripcionWiki) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/wikis`;

    return this.http
      .put(url, { id: idWiki, titulo: tituloWiki, descripcion: descripcionWiki }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaWiki(token, idWiki) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/generales/wikis/eliminar`;

    return this.http
      .post(url, { id: idWiki }, { headers: acceso })
      .pipe(map(data => data));
  }

  // PÁGINAS
  obtenerListadoPaginas(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/paginas`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaPagina(token, estadoPagina, seccionPagina, gradoPagina, materiaPagina, bloquePagina, secuenciaPagina, paginaPagina, ambitoPagina, pslPagina, ejePagina, temaPagina, tieneActividadesPagina, actividadesPagina, tieneQuizPagina, quizPagina, contenidoPagina, imagenesPagina, videosPagina, audiosPagina, galeriasPagina, wikisPagina, contenidoGuia, imagenesGuia) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/paginas`;

    return this.http
      .post(url, { estado: estadoPagina, seccion: seccionPagina, grado: gradoPagina, materia: materiaPagina, bloque: bloquePagina, secuencia: secuenciaPagina, pagina: paginaPagina, ambito: ambitoPagina, psl: pslPagina, eje: ejePagina, tema: temaPagina, tiene_actividades: tieneActividadesPagina, actividades: actividadesPagina, tiene_quiz: tieneQuizPagina, quiz: quizPagina, contenido: contenidoPagina, imagenes: imagenesPagina, videos: videosPagina, audios: audiosPagina, galerias: galeriasPagina, wikis: wikisPagina, contenido2: contenidoGuia, imagenes2: imagenesGuia }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaPagina(token, idPagina, estadoPagina, seccionPagina, gradoPagina, materiaPagina, bloquePagina, secuenciaPagina, paginaPagina, ambitoPagina, pslPagina, ejePagina, temaPagina, tieneActividadesPagina, actividadesPagina, tieneQuizPagina, quizPagina, contenidoPagina, imagenesPagina, videosPagina, audiosPagina, galeriasPagina, wikisPagina, contenidoGuia, imagenesGuia) {
    // actualizarUnaPagina(token, idPagina, estadoPagina, seccionPagina, gradoPagina, materiaPagina, bloquePagina, secuenciaPagina, paginaPagina, ambitoPagina, pslPagina, ejePagina, temaPagina, tieneActividadesPagina, actividadesPagina, tieneQuizPagina, quizPagina, contenidoPagina, imagenesPagina, videosPagina, galeriasPagina, wikisPagina) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/paginas`;

    return this.http
      .put(url, { id: idPagina, estado: estadoPagina, seccion: seccionPagina, grado: gradoPagina, materia: materiaPagina, bloque: bloquePagina, secuencia: secuenciaPagina, pagina: paginaPagina, ambito: ambitoPagina, psl: pslPagina, eje: ejePagina, tema: temaPagina, tiene_actividades: tieneActividadesPagina, actividades: actividadesPagina, tiene_quiz: tieneQuizPagina, quiz: quizPagina, contenido: contenidoPagina, imagenes: imagenesPagina, videos: videosPagina, audios: audiosPagina, galerias: galeriasPagina, wikis: wikisPagina, contenido2: contenidoGuia, imagenes2: imagenesGuia }, { headers: acceso })
      // .put(url, {id: idPagina, estado: estadoPagina, seccion: seccionPagina, grado: gradoPagina, materia: materiaPagina, bloque: bloquePagina, secuencia: secuenciaPagina, pagina: paginaPagina, ambito: ambitoPagina, psl: pslPagina, eje: ejePagina, tema: temaPagina, tiene_actividades: tieneActividadesPagina, actividades: actividadesPagina, tiene_quiz: tieneQuizPagina, quiz: quizPagina, contenido: contenidoPagina, imagenes: imagenesPagina, videos: videosPagina, galerias: galeriasPagina, wikis: wikisPagina}, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaPagina(token, idPagina) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/paginas/eliminar`;

    return this.http
      .post(url, { id: idPagina }, { headers: acceso })
      .pipe(map(data => data));
  }

  restaurarUnaPagina(token, idPagina) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/paginas/restaurar`;

    return this.http
      .post(url, { id: idPagina }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaPaginaDefintiivamente(token, idPagina) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/contenidos/paginas/eliminar/definitivamente`;

    return this.http
      .post(url, { id: idPagina }, { headers: acceso })
      .pipe(map(data => data));
  }

  // MULTIMEDIA
  obtenerEnlaceJeniosBucket() {
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Jenios ${environment.publicJenios}`
    });
    //const urlApi = `${environment.url_jenios}/proyectos/bucket`; HERE
    const urlApi = `${environment.url_bucket}`;

    return this.http
      .get(urlApi, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  subirMultimedia(multimedia: File, urlBase, tipo) {

    console.log("multimedia", multimedia);
    var arreglo = []
    var archivos = new FormData();
    arreglo.push(multimedia);

    arreglo[0].forEach((item, i) => {
      archivos.append('uploadfile', item);
    });

    let urlApi = '';
    if (tipo === 'img') {
      //urlApi = `${urlBase}/api/v1.0.0/ebe/img`;
      urlApi = `${urlBase}/uploads/file`;
    } else if (tipo === 'video') {
      urlApi = `${urlBase}/uploads/file`;
    } else if (tipo === 'audio') {
      urlApi = `${urlBase}/uploads/file`;
    }

    return this.http
      .post(urlApi, archivos, {
        headers: this.headerJenios,
        observe: 'events',
        reportProgress: true
      })
      .pipe(map(data => data));
  }


  insertMultimediaonDB(urlBase: string, multimedia: object, type: string) {
    const apiURL = `${urlBase}/${type}`;
    return this.http
      .post(`${apiURL}`, multimedia, {
        headers: this.headerJenios,
        observe: 'events',
        reportProgress: true
      })
      .pipe(map(data => data));
  }

  // IMAGENES
  obtenerListadoImagenes(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/imagenes`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaImagen(token, idImagen, descripcionImagen) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/imagenes`;

    return this.http
      .put(url, { id: idImagen, descripcion: descripcionImagen }, { headers: acceso })
      .pipe(map(data => data));
  }

  //TODO: corregir eliminar imagen
  eliminarUnaImagen(idImagen, farm, server) {
    const url = `https://f${farm}.s${server}.jenios.mx/api/v1.0.0/ebe/img/delete`;
    return this.http
      .post(url, { photo_id: idImagen, public_key: environment.publicKeyJenios }, { headers: this.headerJenios })
      .pipe(map(data => data));
  }

  // VIDEOS
  obtenerListadoVideos(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/videos`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnVideo(token, idImagen, descripcionImagen) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/videos`;

    return this.http
      .put(url, { id: idImagen, descripcion: descripcionImagen }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnVideo(idVideo, farm, server) {
    const url = `https://f${farm}.s${server}.jenios.mx/api/v1.0.0/ebe/video/delete`;

    return this.http
      .post(url, { video_id: idVideo, public_key: environment.publicKeyJenios }, { headers: this.headerJenios })
      .pipe(map(data => data));
  }

  // AUDIOS
  // AUDIOS
  obtenerListadoAudios(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/audios`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnAudio(token, idImagen, descripcionImagen) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/audios`;

    return this.http
      .put(url, { id: idImagen, descripcion: descripcionImagen }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnAudio(idVideo, farm, server) {
    const url = `https://f${farm}.s${server}.jenios.mx/api/v1.0.0/ebe/audio/delete`;

    return this.http
      .post(url, { audio_id: idVideo, public_key: environment.publicKeyJenios }, { headers: this.headerJenios })
      .pipe(map(data => data));
  }

  // GALERÍAS
  obtenerListadoGalerias(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/galerias`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaGaleria(token, imagenesGaleria, descripcionGaleria) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/galerias`;

    return this.http
      .post(url, { imagenes: imagenesGaleria, descripcion: descripcionGaleria }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaGaleria(token, idGaleria, descripcionGaleria) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/galerias`;

    return this.http
      .put(url, { id: idGaleria, descripcion: descripcionGaleria }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaGaleria(token, idGaleria) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/multimedia/galerias/eliminar`;

    return this.http
      .post(url, { id: idGaleria }, { headers: acceso })
      .pipe(map(data => data));
  }

  // ESPECIALISTAS
  obtenerListadoEspecialistas(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/especialistas`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnEspecialista(token, nombreS, permisosS) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/especialistas`;

    return this.http
      .post(url, { nombre: nombreS, permisos: permisosS }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnEspecialista(token, idEsp, nombreS, permisosS) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/especialistas`;

    return this.http
      .put(url, { id: idEsp, nombre: nombreS, permisos: permisosS }, { headers: acceso })
      .pipe(map(data => data));
  }

  renovarPassEspecialista(token, idEsp) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/especialistas/pass`;

    return this.http
      .put(url, { id: idEsp }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnEspecialista(token, idEsp) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/especialistas/eliminar`;

    return this.http
      .post(url, { id: idEsp }, { headers: acceso })
      .pipe(map(data => data));
  }

  // ESCUELAS
  obtenerListadoEscuelas(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/escuelas`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnaEscuela(token, nombreEsc, seccionesEsc, ciudadEsc, estadoEsc, paisEsc) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/escuelas`;

    return this.http
      .post(url, { nombre: nombreEsc, secciones: seccionesEsc, pais: paisEsc, estado: estadoEsc, ciudad: ciudadEsc }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnaEscuela(token, idEsc, nombreEsc, seccionesEsc, ciudadEsc, estadoEsc, paisEsc) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/escuelas`;

    return this.http
      .put(url, { id: idEsc, nombre: nombreEsc, secciones: seccionesEsc, pais: paisEsc, estado: estadoEsc, ciudad: ciudadEsc }, { headers: acceso })
      .pipe(map(data => data));
  }

  renovarPassEscuela(token, idEsc) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/escuelas/pass`;

    return this.http
      .put(url, { id: idEsc }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnaEscuela(token, idEsc) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/escuelas/eliminar`;

    return this.http
      .post(url, { id: idEsc }, { headers: acceso })
      .pipe(map(data => data));
  }

  // GRUPOS
  obtenerListadoGrupos(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/grupos`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnGrupo(token, idEsc, seccionEsc, gradoGrupo, nombreGrupo) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/grupos`;

    return this.http
      .post(url, { escuela: idEsc, seccion: seccionEsc, grado: gradoGrupo, nombre: nombreGrupo }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarUnGrupo(token, idGrupo, nombreGrupo) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/grupos/nombre`;

    return this.http
      .put(url, { id: idGrupo, nombre: nombreGrupo }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarEstadoDeUnGrupo(token, idGrupo, idEsc, estadoGrupo) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/grupos/estado`;

    return this.http
      .put(url, { id: idGrupo, escuela: idEsc, estado: estadoGrupo }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnGrupo(token, idGrupo) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/grupos/eliminar`;

    return this.http
      .post(url, { id: idGrupo }, { headers: acceso })
      .pipe(map(data => data));
  }

  restaurarUnGrupo(token, idGrupo) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/grupos/restaurar`;

    return this.http
      .post(url, { id: idGrupo }, { headers: acceso })
      .pipe(map(data => data));
  }

  eliminarUnGrupoDefinitivamente(token, idGrupo) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/operacion/grupos/eliminar/def`;

    return this.http
      .post(url, { id: idGrupo }, { headers: acceso })
      .pipe(map(data => data));
  }

  // ALUMNOS
  obtenerListadoAlumnos(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/alumnos`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  confirmarUnPago(token, idEsc, idGrupo, idUser, montoPago) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/confirmar/pago`;

    return this.http
      .post(url, { school: idEsc, group: idGrupo, user: idUser, monto: montoPago }, { headers: acceso })
      .pipe(map(data => data));
  }

  cambiarEstadoAlumno(token, idAlumno, estadoAlumno) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/alumnos/estado`;

    return this.http
      .put(url, { id: idAlumno, accion: estadoAlumno }, { headers: acceso })
      .pipe(map(data => data));
  }

  reemplazarDispAlumno(token, idAlumno, newUser) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/reemplazar/disp`;

    return this.http
      .put(url, { id: idAlumno, user: newUser }, { headers: acceso })
      .pipe(map(data => data));
  }

  // MAESTROS
  obtenerListadoMaestros(token) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/docentes/listado`;

    return this.http
      .get(url, { headers: acceso })
      .pipe(map(data => data));
  }

  obtenerListadoMaestros2(token, idEsc) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/docentes/listado`;

    return this.http
      .post(url, { escuela: idEsc }, { headers: acceso })
      .pipe(map(data => data));
  }

  agregarUnMaestro(token, idEsc, nombreTeacher) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/docentes/registro`;

    return this.http
      .post(url, { school: idEsc, name: nombreTeacher }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarNombreDocente(token, idEsc, idTeacher, nombreTeacher) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/docentes/actualizar`;

    return this.http
      .put(url, { school: idEsc, teacher: idTeacher, name: nombreTeacher }, { headers: acceso })
      .pipe(map(data => data));
  }

  renovarPassDocente(token, idEsc, idTeacher) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/docentes/renovarpass`;

    return this.http
      .put(url, { school: idEsc, teacher: idTeacher }, { headers: acceso })
      .pipe(map(data => data));
  }

  actualizarEstadoDocente(token, idEsc, idTeacher) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/docentes/estado`;

    return this.http
      .put(url, { school: idEsc, teacher: idTeacher }, { headers: acceso })
      .pipe(map(data => data));
  }

  liberarEquipoDocente(token, idEsc, idTeacher) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/docentes/disp`;

    return this.http
      .put(url, { school: idEsc, teacher: idTeacher }, { headers: acceso })
      .pipe(map(data => data));
  }

  // CLASES
  consultarClases(token, idEsc, idGrupo) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/clases/listado`;

    return this.http
      .post(url, { escuela: idEsc, grupo: idGrupo }, { headers: acceso })
      .pipe(map(data => data));
  }

  asignarUnaClase(token, idEsc, idGrupo, idClase, idTeacher) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/clases/asignar`;

    return this.http
      .post(url, { school: idEsc, group: idGrupo, class: idClase, teacher: idTeacher }, { headers: acceso })
      .pipe(map(data => data));
  }

  liberarUnaClase(token, idEsc, idGrupo, idClase, idTeacher) {
    const acceso: HttpHeaders = this.funcionHeader(token);
    const url = `${environment.url_principal}/escolares/admin/clases/liberar`;

    return this.http
      .post(url, { school: idEsc, group: idGrupo, class: idClase, teacher: idTeacher }, { headers: acceso })
      .pipe(map(data => data));
  }
}
