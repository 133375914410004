import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestfulApiService } from '../../../services/restful/restful-api.service';

@Component({
  selector: 'app-teacher-list',
  templateUrl: './teacher-list.component.html',
  styleUrls: ['./teacher-list.component.css']
})
export class TeacherListComponent implements OnInit {

  public showNewPass = false;
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoRestablecer = false;
  public permisoCambiarEstado = false;
  public permisoReemplazar = false;
  public teachers = [];
  private positionTeacher = 0;
  private idEscuela = '';
  public idEscuela2 = '';
  public nombreTeacher = '';
  public usuarioMaestro = '';
  public mostrarInfoTeacher = false;
  public newPass = '';
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) { 
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].registrar_maestros;
      this.permisoEditar = info.permisos[0].actualizar_maestros;
      this.permisoRestablecer = info.permisos[0].renovar_pass_maestros;
      this.permisoCambiarEstado = info.permisos[0].cambiar_estado_maestros;
      this.permisoReemplazar = info.permisos[0].reemplazar_disp_maestros;
      if(info.permisos[0].visualizar_maestros === true){
        let listado;
        this.api.obtenerListadoMaestros(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).teachers;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Maestros");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Maestros");
          }
        }, () => {
          this.teachers = listado;
          this.teachers.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
          this.notify.showInfo("Información actualizada", "Maestros");
        });
      } else if(info.permisos[0].registrar_maestros !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      //console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenInfoOfTeacher(name, tipo){
    if(tipo === 'escuela'){
      name.target.value = name.target.value.toUpperCase();
      this.idEscuela = name.target.value.toUpperCase();
    }
    if(tipo === 'maestro'){
      name.target.value = name.target.value.toUpperCase();
      this.nombreTeacher = name.target.value.toUpperCase();
    }
  }

  mostrarInfoMaestro(idTeacher, idEsc, nombreTeacher, positionTeacher){
    this.positionTeacher = positionTeacher;
    this.usuarioMaestro = idTeacher;
    this.idEscuela = idEsc;
    this.idEscuela2 = idEsc;
    this.nombreTeacher = nombreTeacher;
  }

  agregarMaestro(){
    if(this.idEscuela.trim() === ''){
      this.notify.showWarning('ID ESCUELA REQUERIDO', 'Maestros');
    }
    if(this.nombreTeacher.trim() === ''){
      this.notify.showWarning('NOMBRE DEL MAESTRO REQUERIDO', 'Maestros');
    }
    if(this.idEscuela.trim() !== '' && this.nombreTeacher.trim() !== ''){
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Maestros');
        let userMaestro;
        let passMaestro;
        let nombreMaestro;

        this.api.agregarUnMaestro(this.token, this.idEscuela.trim(), this.nombreTeacher.trim()).subscribe(respuesta => {
          userMaestro = JSON.parse(JSON.stringify(respuesta)).user;
          passMaestro = JSON.parse(JSON.stringify(respuesta)).pass;
          nombreMaestro = JSON.parse(JSON.stringify(respuesta)).name;
          this.mostrarInfoTeacher = true;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Maestros');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('La escuela no existe', 'Maestros');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Maestros');
          }
        }, () => {
          this.usuarioMaestro = userMaestro;
          this.nombreTeacher = nombreMaestro;
          this.newPass = passMaestro;
          this.teachers.push({
            id: userMaestro,
            escuela: this.idEscuela,
            activo: true,
            nombre: nombreMaestro,
            device: false
          });
          this.teachers.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
          this.notify.showInfo('Maestro agregado con éxito', 'Maestros');
          // this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  actualizarMaestro(){
    if(this.nombreTeacher.trim() === ''){
      this.notify.showWarning('NOMBRE DEL MAESTRO REQUERIDO', 'Maestros');
    }
    if(this.nombreTeacher.trim() !== ''){
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Maestros');

        let estadoRespuesra;
        this.api.actualizarNombreDocente(this.token, this.idEscuela.trim(), this.usuarioMaestro.trim(), this.nombreTeacher.trim()).subscribe(respuesta => {
          estadoRespuesra = JSON.parse(JSON.stringify(respuesta)).estado;
          this.mostrarInfoTeacher = true;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Maestros');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Maestros');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Maestros');
          }
        }, () => {
          if(estadoRespuesra === 200){
            this.notify.showInfo('Maestro actualizado con éxito', 'Maestros');
            this.teachers[this.positionTeacher].nombre = this.nombreTeacher.trim().toUpperCase();
            this.teachers.sort(function(a, b){
              if(a.nombre < b.nombre) { return -1; }
            });
            this.hideAllModals();
          } else {
            this.notify.showError('Ocurrió un error inesperado', 'Maestros');
          }
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  renovarPassMaestro(){
    this.showNewPass = true;
    this.newPass = 'Esperando...';

    this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Maestros');

        let estadoRespuesra;
        let newPass;
        this.api.renovarPassDocente(this.token, this.idEscuela.trim(), this.usuarioMaestro.trim()).subscribe(respuesta => {
          estadoRespuesra = JSON.parse(JSON.stringify(respuesta)).estado;
          newPass = JSON.parse(JSON.stringify(respuesta)).pass;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Maestros');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Maestros');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Maestros');
          }
        }, () => {
          if(estadoRespuesra === 200){
            this.newPass = newPass;
            this.notify.showInfo('Contraseña actualizada con éxito', 'Maestros');
          } else {
            this.notify.showError('Ocurrió un error inesperado', 'Maestros');
          }
        });
      } else {
        this.router.navigate(['', 'auth']);
      }

  }

  cambiarEstadoMaestro(){
    this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Maestros');

        let estadoRespuesra;
        let activoMaestro;
        this.api.actualizarEstadoDocente(this.token, this.idEscuela.trim(), this.usuarioMaestro.trim()).subscribe(respuesta => {
          estadoRespuesra = JSON.parse(JSON.stringify(respuesta)).estado;
          activoMaestro = JSON.parse(JSON.stringify(respuesta)).activo;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Maestros');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Maestros');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Maestros');
          }
        }, () => {
          if(estadoRespuesra === 200){
            this.teachers[this.positionTeacher].activo = activoMaestro;
            this.notify.showInfo('Se actualizó el estado con éxito', 'Maestros');
          } else {
            this.notify.showError('Ocurrió un error inesperado', 'Maestros');
          }
        });
      } else {
        this.router.navigate(['', 'auth']);
      }

  }

  liberarDispMaestro(){
    this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Maestros');

        let estadoRespuesra;
        let deviceMaestro;
        this.api.liberarEquipoDocente(this.token, this.idEscuela.trim(), this.usuarioMaestro.trim()).subscribe(respuesta => {
          estadoRespuesra = JSON.parse(JSON.stringify(respuesta)).estado;
          deviceMaestro = JSON.parse(JSON.stringify(respuesta)).device;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Maestros');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Maestros');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Maestros');
          }
        }, () => {
          if(estadoRespuesra === 200){
            this.teachers[this.positionTeacher].device = deviceMaestro;
            this.notify.showInfo('Se liberó el dispositivo con éxito', 'Maestros');
          } else {
            this.notify.showError('Ocurrió un error inesperado', 'Maestros');
          }
        });
      } else {
        this.router.navigate(['', 'auth']);
      }

  }

  hideAllModals(){
    this.modal.dismissAll();
    this.showNewPass = false;
    this.mostrarInfoTeacher = false;
    this.usuarioMaestro = '';
    this.idEscuela = '';
    this.idEscuela2 = '';
    this.nombreTeacher = '';
    this.newPass = '';
  }

}
