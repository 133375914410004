import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestfulApiService } from '../../../services/restful/restful-api.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {

  public id = '';
  private id2 = '';
  public schools = [];
  public grades = [];
  public grades2 = [];
  public levels = [];
  public levels2 = [];
  public groups = [];
  private schoolId = '';
  public groupName = '';
  public estadoGrupo = false;
  public nameOfGroup = '';
  public nameOfGroup2 = '';
  public escuelaSeleccionada = '';
  public seccionSeleccionada = '';
  public gradoSeleccionado = '';
  public escuelaSeleccionada2 = '';
  public seccionSeleccionada2 = '';
  public gradoSeleccionado2 = '';
  public permisoVisualizarClases = false;
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoCambiarEstado = false;
  public permisoEliminar = false;
  public permisoRestablecer = false;
  public permisoEliminarDef = false;
  public puedeUtilizarBoton = false;
  private position = 0;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoVisualizarClases = info.permisos[0].visualizar_clases;
      this.permisoAgregar = info.permisos[0].agregar_grupo;
      this.permisoEditar = info.permisos[0].actualizar_grupo;
      this.permisoCambiarEstado = info.permisos[0].cambiar_estado_grupo;
      this.permisoEliminar = info.permisos[0].eliminar_grupo;
      this.permisoRestablecer = info.permisos[0].restaurar_grupo;
      this.permisoEliminarDef = info.permisos[0].eliminar_definitivamente_grupo;
      if(info.permisos[0].visualizar_grupos === true){
        let listado;
        this.api.obtenerListadoGrupos(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).grupos;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Grupos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Grupos");
          }
        }, () => {
          this.groups = listado;
          this.groups.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
          this.notify.showInfo("Información actualizada", "Grupos");
        });
        let listado2;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Grupos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Grupos");
          }
        }, () => {
          this.levels = listado2;
          this.levels.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
        });
        let listado3;
        this.api.obtenerListadoEscuelas(this.token).subscribe(respuesta => {
          listado3 = JSON.parse(JSON.stringify(respuesta)).escuelas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Grupos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Grupos");
          }
        }, () => {
          this.schools = listado3;
          this.schools.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
        });
        let listado4;
        this.api.obtenerListadoGrados(this.token).subscribe(respuesta => {
          listado4 = JSON.parse(JSON.stringify(respuesta)).grados;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Grupos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Grupos");
          }
        }, () => {
          this.grades = listado4;
          this.grades.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
          this.puedeUtilizarBoton = true;
        });
      } else if(info.permisos[0].agregar_grupo !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      //console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  mostrarInfoGrupo(idGrupo, idEscuela, idNivel, idGrado, nombreGrupo, estadoGrupo, posicionGrupo){
    this.id = idGrupo;
    this.schoolId = idEscuela;
    this.escuelaSeleccionada2 = idEscuela;
    this.seleccionarEscuela();
    this.groupName = nombreGrupo;
    this.position = posicionGrupo;
    this.estadoGrupo = estadoGrupo;
    this.seccionSeleccionada2 = idNivel;
    this.seleccionarNivelAcademico();
    this.gradoSeleccionado2 = idGrado;
  }

  listenInfoOfGroup(name, tipo){
    if(tipo === 'grupo'){
      name.target.value = name.target.value.toUpperCase();
      this.nameOfGroup = name.target.value.toUpperCase();
      this.nameOfGroup2 = name.target.value.toUpperCase();
    }
  }

  listenIdOfGroup(id){
    this.id2 = id.target.value;
  }

  seleccionarEscuela(){
    if(this.escuelaSeleccionada !== ''){
      let secciones = this.filtrarEscuelas(this.escuelaSeleccionada)[0].secciones;
      let seccionesArray = [];
      let contador = 0;
      for(let i = 0; i < secciones.length; i++){
        contador = contador + 1;
        for(let k = 0; k < this.levels.length; k++){
          if(secciones[i].id === this.levels[k].id){
            seccionesArray.push({
              id: this.levels[k].id,
              nombre: this.levels[k].nombre
            });
          }
        }
      }
      if(contador === secciones.length){
        this.levels2 = seccionesArray;
      }
    } else if(this.escuelaSeleccionada2 !== ''){
      let secciones = this.filtrarEscuelas(this.escuelaSeleccionada2)[0].secciones;
      let seccionesArray = [];
      let contador = 0;
      for(let i = 0; i < secciones.length; i++){
        contador = contador + 1;
        for(let k = 0; k < this.levels.length; k++){
          if(secciones[i].id === this.levels[k].id){
            seccionesArray.push({
              id: this.levels[k].id,
              nombre: this.levels[k].nombre
            });
          }
        }
      }
      if(contador === secciones.length){
        this.levels2 = seccionesArray;
      }
    } else {
      this.levels2 = [];
      this.grades2 = [];
      this.gradoSeleccionado = '';
      this.gradoSeleccionado2 = '';
      this.seccionSeleccionada = '';
      this.seccionSeleccionada2 = '';
    }
  }

  seleccionarNivelAcademico(){
    if(this.seccionSeleccionada !== ''){
      this.grades2 = this.filtrarGradosPorSeccion(this.seccionSeleccionada);
    } else if(this.seccionSeleccionada2 !== ''){
      this.grades2 = this.filtrarGradosPorSeccion(this.seccionSeleccionada2);
    } else {
      this.grades2 = [];
      this.gradoSeleccionado = '';
      this.gradoSeleccionado2 = '';
    }
  }

  hideAllModals(){
    this.modal.dismissAll();
    this.levels2 = [];
    this.grades2 = [];
    this.escuelaSeleccionada = '';
    this.gradoSeleccionado = '';
    this.seccionSeleccionada = '';
  }

  agregarGrupo(){
    if(this.nameOfGroup.trim() !== '' && this.escuelaSeleccionada !== ''
    && this.seccionSeleccionada !== '' && this.gradoSeleccionado !== ''){
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Grupos');
        let idRespuesta;
        this.api.agregarUnGrupo(this.token, this.escuelaSeleccionada, this.seccionSeleccionada, this.gradoSeleccionado, this.nameOfGroup).subscribe(respuesta => {
          idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Grupos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Grupos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Grupos');
          }
        }, () => {
          this.groups.push({
            id: idRespuesta,
            nombre: this.nameOfGroup,
            activo: true,
            estado: 'En operacion',
            escuela: this.escuelaSeleccionada,
            seccion: this.seccionSeleccionada,
            grado: this.gradoSeleccionado,
            alumnos: []
          });
          this.groups.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
          this.notify.showInfo('Grupo agregado con éxito', 'Grupos');
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    } else {
      if(this.nameOfGroup.trim() === ''){
        this.notify.showWarning('Asigne un nombre al grupo', 'Grupos');
      }
      if(this.escuelaSeleccionada.trim() === ''){
        this.notify.showWarning('Seleccione una escuela', 'Grupos');
      }
      if(this.seccionSeleccionada.trim() === ''){
        this.notify.showWarning('Seleccione un nivel académico', 'Grupos');
      }
      if(this.gradoSeleccionado.trim() === ''){
        this.notify.showWarning('Seleccione un grado de estudio', 'Grupos');
      }
    }
  }

  actualizarGrupo(){
    if(this.nameOfGroup2.trim() !== ''){
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Grupos');
        this.api.actualizarUnGrupo(this.token, this.id, this.nameOfGroup2).subscribe(respuesta => {
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Grupos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Grupos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Grupos');
          }
        }, () => {
          this.groups[this.position].nombre = this.nameOfGroup2;
          this.groups.sort(function(a, b){
            if(a.nombre < b.nombre) { return -1; }
          });
          this.notify.showInfo('Grupo agregado con éxito', 'Grupos');
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    } else {
      if(this.nameOfGroup2.trim() === ''){
        this.notify.showWarning('Asigne un nombre al grupo', 'Grupos');
      }
    }
  }

  eliminarGrupo(){
    if(this.id.trim() !== this.id2.trim()){
      this.notify.showWarning('Los ID no coinciden', 'Grupos');
    } else {
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Grupos');
        this.api.eliminarUnGrupo(this.token, this.id).subscribe(respuesta => {
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Grupos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Grupos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Grupos');
          }
        }, () => {
          this.groups[this.position].estado = 'Eliminado';
          this.notify.showInfo('Grupo eliminado correctamente', 'Grupos');
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  recuperarGrupo(){
    if(this.id.trim() !== this.id2.trim()){
      this.notify.showWarning('Los ID no coinciden', 'Grupos');
    } else {
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Grupos');
        this.api.restaurarUnGrupo(this.token, this.id).subscribe(respuesta => {
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Grupos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Grupos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Grupos');
          }
        }, () => {
          this.groups[this.position].estado = 'En operacion';
          this.notify.showInfo('Grupo recuperado correctamente', 'Grupos');
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  eliminarGrupoDef(){
    if(this.id.trim() !== this.id2.trim()){
      this.notify.showWarning('Los ID no coinciden', 'Grupos');
    } else {
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Grupos');
        this.api.eliminarUnGrupoDefinitivamente(this.token, this.id).subscribe(respuesta => {
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Grupos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Grupos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Grupos');
          }
        }, () => {
          this.groups.splice(this.position, 1);
          this.notify.showInfo('Grupo eliminado de manera definitiva', 'Grupos');
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  cambiarEstadoGrupo(estado){
    //console.log("MONTO A PAGAR: ", this.montoDePago);
    if(this.id.trim() !== this.id2.trim()){
      this.notify.showWarning('Los ID no coinciden', 'Grupos');
    } else {
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.notify.showInfo('Procesando solicitud...', 'Grupos');
        this.api.actualizarEstadoDeUnGrupo(this.token, this.id, this.escuelaSeleccionada2, estado).subscribe(respuesta => {
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Grupos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El grupo no existe', 'Grupos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Grupos');
          }
        }, () => {
          let key = '';
          let activo = false;
          if(estado === 'Activar'){
            key = 'activó';
            activo = true;
          } else if(estado === 'Desactivar'){
            key = 'desactivó';
            activo = false;
          }
          this.notify.showInfo(`Se ${key} el grupo correctamente`, 'Grupos');
          this.groups[this.position].activo = activo;
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  visualizarClases(){
    sessionStorage.setItem('idEscuela', this.escuelaSeleccionada2.trim());
    sessionStorage.setItem('idGrupo', this.id.trim());
    sessionStorage.setItem('nombreGrupo', this.groupName.trim());
    this.router.navigate(['', 'admin', 'classes']);
  }

  filtrarEscuelas(key: string ) {
    return this.schools.filter(object => {
      return object.id === key;
    });
  }

  filtrarGradosPorSeccion(key: string ) {
    return this.grades.filter(object => {
      return object.seccion === key;
    });
  }

}
