import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestfulApiService } from '../../../services/restful/restful-api.service';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent implements OnInit {

  public id = '';
  private id2 = '';
  private position = 0;
  public schoolId = '';
  public groupId = '';
  private newUserId = '';
  public estadoAlumno = false;
  public montoaPagar = 0;
  public montoDePago = 0;
  public montoDePago2 = 0;
  private montoDePago3 = 0;
  public students = [];
  public students2 = [];
  public schools = [];
  public groups = [];
  public groups2 = [];
  public permisoConfirmarPago = false;
  public permisoCambiarEstado = false;
  public permisoReemplazarDisp = false;
  public schoolSelected = '';
  public groupSelected = '';
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoConfirmarPago = info.permisos[0].verificar_pago;
      this.permisoCambiarEstado = info.permisos[0].cambiar_estado_alumno;
      this.permisoReemplazarDisp = info.permisos[0].reemplazar_disp_alumno;
      if(info.permisos[0].visualizar_alumnos === true){
        let listado;
        this.api.obtenerListadoAlumnos(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).alumnos;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Alumnos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Alumnos");
          }
        }, () => {
          this.students = listado;
          this.students2 = listado;
          this.students.sort(function(a, b){
            if(a.id < b.id) { return -1; }
          });
          this.notify.showInfo("Información actualizada", "Alumnos");
        });
        let listado2;
        this.api.obtenerListadoEscuelas(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).escuelas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.schools = listado2;
          this.schools.sort(function(a, b){
            if(a.id < b.id) { return -1; }
          });
        });
        let listado3;
        this.api.obtenerListadoGrupos(this.token).subscribe(respuesta => {
          listado3 = JSON.parse(JSON.stringify(respuesta)).grupos;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.groups = listado3;
          this.groups.sort(function(a, b){
            if(a.id < b.id) { return -1; }
          });
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      //console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  seleccionarEscuela(){
    if(this.schoolSelected !== ''){
      this.groups2 = [];
      this.groupSelected = '';
      this.groups2 = this.filtrarGruposPorEscuela(this.schoolSelected);
      this.students = [];
    } else {
      this.groups2 = [];
      this.students = this.students2;
    }
  }

  seleccionarGrupo(){
    if(this.groupSelected !== ''){
      this.students = this.filtrarAlumnosPorGrupo(this.groupSelected);
    } else {
      this.students = [];
    }
  }

  mostrarInfoAlumno(idAlumno, idEscuela, idGrupo, montoaPagar, estadoAlumno, posicionAlumno){
    this.id = idAlumno;
    this.schoolId = idEscuela;
    this.groupId = idGrupo;
    this.montoaPagar = montoaPagar;
    this.position = posicionAlumno;
    this.estadoAlumno = estadoAlumno;
  }

  hideAllModals(){
    this.modal.dismissAll();
  }

  listenInfoOfStudent(name, tipo){
    if(tipo === 'monto'){
      this.montoDePago = Number(name.target.value);
      this.montoDePago2 = Number(name.target.value);
    }
  }

  listenIdOfStudent(id){
    this.id2 = id.target.value;
  }

  listenNewUserOfStudent(user){
    this.newUserId = user.target.value;
  }

  filtrarGruposPorEscuela(key: string ) {
    return this.groups.filter(object => {
      return object.escuela === key;
    });
  }

  filtrarAlumnosPorGrupo(key: string ) {
    return this.students2.filter(object => {
      return object.grupo === key;
    });
  }

  confirmarPago(){
    // console.log("ID ALUMNO: ", this.id);
    // console.log("ESCUELA: ", this.schoolId);
    // console.log("GRUPO: ", this.groupId);
    // console.log("MONTO: ", this.montoDePago);
    //console.log("MONTO A PAGAR: ", this.montoDePago);
    if(this.montoDePago >= this.montoaPagar){
      this.notify.showInfo('Procesando solicitud...', 'Alumnos');
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      // console.log("TOKEN: ", this.token);
      // console.log("TOKEN EXPIRADO: ", tokenEspirado);
      if(!tokenEspirado && this.token !== null){
        this.api.confirmarUnPago(this.token, this.schoolId, this.groupId, this.id, this.montoDePago).subscribe(respuesta => {
          // console.log("RESPUESTA: ", respuesta);
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Alumnos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El alumno no existe', 'Alumnos');
          }
          if (err.status === 403) {
            this.notify.showWarning('No se puede confirmar el pago del alumno', 'Alumnos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Alumnos');
          }
        }, () => {
          this.notify.showInfo('Se confirmó el pago correctamente', 'Alumnos');
          this.students[this.position].estado = 'Pagado';
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.notify.showWarning('Ingrese una cantidad igual o mayor al monto a pagar', 'Alumnos');
    }
  }

  cambiarEstadoAlumno(estado){
    //console.log("MONTO A PAGAR: ", this.montoDePago);
    if(this.id.trim() !== this.id2.trim()){
      this.notify.showWarning('Los ID no coinciden', 'Alumnos');
    } else {
      this.notify.showInfo('Procesando solicitud...', 'Alumnos');
      this.token = this.auth.obtenertoken();
      const helper = new JwtHelperService();
      const tokenEspirado = helper.isTokenExpired(this.token);
      if(!tokenEspirado && this.token !== null){
        this.api.cambiarEstadoAlumno(this.token, this.id, estado).subscribe(respuesta => {
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError('Error 400', 'Alumnos');
          }
          if (err.status === 401) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 404) {
            this.notify.showWarning('El alumno no existe', 'Alumnos');
          }
          if (err.status === 500) {
            this.notify.showError('Error 500', 'Alumnos');
          }
        }, () => {
          let key = '';
          let activo = false;
          if(estado === 'Activar'){
            key = 'activó';
            activo = true;
          } else if(estado === 'Desactivar'){
            key = 'desactivó';
            activo = false;
          }
          this.notify.showInfo(`Se ${key} el alumno correctamente`, 'Alumnos');
          this.students[this.position].activo = activo;
          this.hideAllModals();
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
  }

  reemplazarDispositivo(){
    this.notify.showInfo('Procesando solicitud...', 'Alumnos');
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    if(!tokenEspirado && this.token !== null){
      this.api.reemplazarDispAlumno(this.token, this.id, this.newUserId).subscribe(respuesta => {
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Alumnos');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El alumno no existe', 'Alumnos');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Alumnos');
        }
      }, () => {
        this.notify.showInfo('Se reemplazó el dispositivo correctamente', 'Alumnos');
        this.hideAllModals();
        for(let i = 0; i < this.students.length; i++){
          if(this.students[i].id === this.newUserId){
            this.students.splice(i, 1);
            this.students[this.position].id = this.newUserId;
          }
        }
      });
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

}
